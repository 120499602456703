import { EventTypes } from '../../types';
import { createBigQueryEvent } from '../index';

const { language, currency, locale } = EventTypes;

export const BigQueryEventTypes = {
    [locale.modal.triggered]: createBigQueryEvent('clicked_on_localization_preferences'),
    [locale.modal.closed]: createBigQueryEvent('clicked_on_localization_preferences_close'),
    [locale.tab.language]: createBigQueryEvent('clicked_on_language_settings'),
    [locale.tab.currency]: createBigQueryEvent('clicked_on_currency_settings'),
    [language.modal.triggered]: createBigQueryEvent('clicked_on_language_settings'),
    [language.modal.closed]: createBigQueryEvent('clicked_on_language_settings_close'),
    [language.localeMessage.show]: createBigQueryEvent('suggest_locale_change_notification_show'),
    [language.localeMessage.closed]: createBigQueryEvent('suggest_locale_change_notification_close'),
    [language.localeMessage.clicked]: createBigQueryEvent('clicked_on_suggest_locale_change_notification'),
    [language.localeMessage.update]: createBigQueryEvent('language_update_success'),
    [currency.modal.triggered]: createBigQueryEvent('clicked_on_currency_settings'),
    [currency.modal.closed]: createBigQueryEvent('clicked_on_currency_settings_close'),
};
