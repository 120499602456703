import { EventTypes } from '../../types';
import { createMixpanelEvent } from '../index';

const { language, currency, locale } = EventTypes;

export const MixpanelEventTypes = {
    [language.modal.triggered]: createMixpanelEvent('Language Selection Modal - Triggered'),
    [language.modal.opened]: createMixpanelEvent('Language Selection Modal - Opened'),
    [language.modal.closed]: createMixpanelEvent('Language Selection Modal - Closed'),
    [language.update.success]: createMixpanelEvent('Language Updated Successfully'),
    [language.update.fail]: createMixpanelEvent('Language Update Failed'),
    [language.localeMessage.show]: createMixpanelEvent('Change language notification - show'),
    [language.localeMessage.closed]: createMixpanelEvent('Change language notification - dismiss'),
    [language.localeMessage.clicked]: createMixpanelEvent('Change language notification - click'),
    [currency.modal.triggered]: createMixpanelEvent('Currency Selection Modal - Triggered'),
    [currency.modal.opened]: createMixpanelEvent('Currency Selection Modal - Opened'),
    [currency.modal.closed]: createMixpanelEvent('Currency Selection Modal - Closed'),
    [currency.update.success]: createMixpanelEvent('Currency Updated Successfully'),
    [currency.update.fail]: createMixpanelEvent('Currency Update Failed'),
    [locale.modal.triggered]: createMixpanelEvent('Locale Selection Modal - Triggered'),
    [locale.modal.opened]: createMixpanelEvent('Locale Selection Modal - Opened'),
    [locale.modal.closed]: createMixpanelEvent('Locale Selection Modal - Closed'),
    [locale.tab.currency]: createMixpanelEvent('Currency Tab - Clicked'),
    [locale.tab.language]: createMixpanelEvent('Language Tab - Clicked'),
};
