export const MEDIA_GRID_SM = 600;

export const MEDIA_GRID_LARGE = 1160;

export const MESSAGE_VIEW_COUNT_KEY = 'promote_local_sites_for_countries_view_count';

export const MESSAGE_VIEW_COUNT_THRESHOLD = 2;

export const HIDE_LOCALE_MESSAGE_COOKIE = 'hide_locale_redirect_message';

export const FIVERR_BASE_DOMAIN_NAME = 'fiverr.com';

export const COOKIE_EXPIRATION = 30;

export const PROMOTED_COUNTRIES_MAP = {
    DE: 'de-DE',
    AT: 'de-DE',
    NL: 'nl-NL',
    FR: 'fr-FR',
};

export const PROMOTED_SOURCES = ['user_menu', 'sidebar'];

export const CONTROL_VARIANT = 1;
