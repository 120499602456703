import uaParser from 'ua-parser-js';
import { TOUCH_DEVICES } from './constants';

/**
 * Checks whether the current device is considered a touch device (phone or tablet).
 * @param {String} [userAgent] User agent string.
 * @return {Boolean}
 */
const isTouchDevice = (userAgent) => TOUCH_DEVICES.includes(uaParser(userAgent).device.type);

export default isTouchDevice;
