import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { PACKAGE_CLASSNAME } from '../../constants';
import { isFullWidth } from '../../PropTypes';

import './style.scss';

const Layout = ({ isSticky, isTransparent, className, children }, { isFullWidth }) => {
    const layoutClassName = classnames(
        PACKAGE_CLASSNAME,
        'fiverr-header',
        {
            'header-sticky': isSticky,
            'header-transparent': isTransparent,
            'header-full-width': isFullWidth,
        },
        className
    );

    return <header className={layoutClassName}>{children}</header>;
};

Layout.propTypes = {
    isSticky: PropTypes.bool,
    isTransparent: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

Layout.defaultProps = {
    isSticky: false,
    isTransparent: false,
    children: null,
};

Layout.contextTypes = {
    isFullWidth,
};

export default Layout;
