import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const Item = ({ className, children, displayFromSM, displayFromMD, displayFromLG, displayFromXL }) => {
    const itemClassName = classnames(
        {
            'display-from-sm': displayFromSM,
            'display-from-md': displayFromMD,
            'display-from-lg': displayFromLG,
            'display-from-xl': displayFromXL,
        },
        className
    );

    return <li className={itemClassName}>{children}</li>;
};

Item.propTypes = {
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    displayFromSM: PropTypes.bool,
    displayFromMD: PropTypes.bool,
    displayFromLG: PropTypes.bool,
    displayFromXL: PropTypes.bool,
};

Item.defaultProps = {
    className: '',
    children: null,
    displayFromSM: false,
    displayFromMD: false,
    displayFromLG: false,
    displayFromXL: false,
};

export default Item;
