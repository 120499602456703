import React from 'react';
import { func, object, string, bool, oneOfType } from 'prop-types';
import { isFunction, noop } from 'lodash';
import { Container } from '@fiverr-private/layout_components';
import { Icon, Tag } from '@fiverr-private/fit';
import { I18n } from '@fiverr-private/i18n-react';
import { grey_900 } from '@fiverr-private/sass/helpers';
import { s_lock } from '@fiverr-private/icons';
import withTranslation from '../../../lib/withTranslation';
import { renderable } from '../../../PropTypes';
import { reportBigQueryClick, reportMixpanelClick } from '../../../utils/reporter';

const Link = ({
    i18n,
    onClick = noop,
    bigQueryData,
    mpData,
    children,
    isNew = false,
    isBeta = false,
    isLocked = false,
    isPro = false,
    ...props
}) => {
    const handleClick = (event) => {
        reportBigQueryClick(bigQueryData);
        reportMixpanelClick(mpData);

        onClick(event);
    };

    return (
        <a {...props} onClick={handleClick}>
            {isFunction(children) ? children(i18n) : children}
            {isNew && (
                <Tag key="tag" className="nav-link-tag" color={Tag.COLORS.PINK} theme={Tag.THEMES.FULL}>
                    {i18n.t('header.new')}
                </Tag>
            )}
            {isBeta && (
                <Tag key="tag" color={Tag.COLORS.BLUE} className="nav-link-tag">
                    {i18n.t('header.beta')}
                </Tag>
            )}
            {isPro && (
                <Container
                    transform="uppercase"
                    borderRadius="sm"
                    backgroundColor="business_800"
                    width="81px"
                    height="20px"
                    color="white"
                    fontSize="b_xxs"
                    display="inlineFlex"
                    alignItems="center"
                    justifyContent="center"
                    fontWeight="bold"
                    marginLeft="3"
                >
                    <I18n k="header.fiverr_pro" />
                </Container>
            )}
            {isLocked && (
                <Icon color={grey_900} className="seller-analytics-link-lock-icon">
                    {s_lock}
                </Icon>
            )}
        </a>
    );
};

Link.propTypes = {
    bigQueryData: object,
    mpData: object,
    onClick: func,
    className: string,
    children: oneOfType([renderable, func]),
    href: string,
    isNew: bool,
    isBeta: bool,
    isLocked: bool,
    isPro: bool,
    i18n: object,
};

export default withTranslation(Link);
