import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import _ from 'lodash';
import Popover from '@fiverr-private/popover/src/Popover';
import { PREVENT_CLOSE_TARGETS_CLASS_NAMES } from './constants';

import './style.scss';

/**
 * There are some menu items that opens another toggler (popver) level over the
 * <PopoverItems> (the localSettings for instance).
 * When this happens the toggler tries to close the current one, and we don't want that to happen.
 * To fix this we pass "shouldPreventCloseAction" prop to the popover to prevent closing when clicked on such menu item.
 *
 * @param target is the menu item that is clicked
 * @returns {boolean}
 */
const shouldPreventCloseAction = (target) => {
    if (!target) {
        return false;
    }

    return !!PREVENT_CLOSE_TARGETS_CLASS_NAMES.find((className) => !!target.closest(`.${className}`));
};

const PopoverItems = ({
    target,
    width,
    children,
    className,
    name = 'nav-popover-items',
    placement,
    onOpen = _.noop,
}) => (
    <Popover
        name={name}
        direction="bottom"
        placement={placement}
        shouldPreventCloseAction={shouldPreventCloseAction}
        target={({ preventCloseAction, toggle, isOpen }) => {
            const handleClick = (...args) => {
                onOpen();
                toggle(...args);
            };

            return (
                <button
                    onClick={handleClick}
                    ref={preventCloseAction}
                    className={classNames('nav-popover-items-toggler', { 'is-open': isOpen })}
                >
                    {target}
                </button>
            );
        }}
    >
        <ul className={classNames(className, 'nav-popover-items-content')} style={{ width }}>
            {children}
        </ul>
    </Popover>
);

PopoverItems.propTypes = {
    target: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    onOpen: PropTypes.func,
    width: PropTypes.string,
    placement: PropTypes.string,
    name: PropTypes.string,
    className: PropTypes.string,
};

PopoverItems.defaultProps = {
    width: 'auto',
    placement: 'center',
};

export default PopoverItems;
