import { stats as unprefixedStats } from '@fiverr-private/obs';
import { GRAFANA_PREFIX } from './grafana.constants';
import { GrafanaEventName } from './grafana.types';

type MetricValue = NonNullable<Parameters<typeof unprefixedStats.count>[2]>;

const createGrafanaReporter = () => {
    const stats = unprefixedStats.prefix(GRAFANA_PREFIX);

    const count = (metric: GrafanaEventName, value?: MetricValue) => {
        stats.count(metric, value);
    };

    return {
        count,
    };
};

export const globalGrafanaReporter = createGrafanaReporter();
