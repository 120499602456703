import React from 'react';
import PropTypes from 'prop-types';
import Row from '../Row';
import './style.scss';

function MainRow({ children }) {
    return <Row className="row-main">{children}</Row>;
}

MainRow.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

MainRow.defaultProps = {
    children: null,
};

export default MainRow;
