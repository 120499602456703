import React, { PureComponent } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import {
    abTests,
    breadcrumbs,
    headerType,
    localeSettings,
    currencies,
    locales,
    rollouts,
    notificationSoundEnabled,
    title,
    user,
    categories,
    showBuyerNavigation,
    userAgent,
    requestUrl,
    referrer,
    pageCtxId,
    isFullWidth,
    showNaviconButton,
} from '../../PropTypes';
import { deserializeCurrencies, parseBalance, getCurrencyObj } from '../currencySerializers';
import { deserializeLocales } from '../localeSerializers';

const withContext = (Composite) => {
    class ContextWrapper extends PureComponent {
        getChildContext() {
            const {
                displayedLocale,
                selectedCurrencyRate,
                supportedCurrencies,
                currencies,
                supportedLanguages,
                locales,
                user,
                rollouts,
                abTests,
                headerType,
                categories,
                showBuyerNavigation,
                requestUrl,
                notificationSoundEnabled,
                isFullWidth = false,
                showNaviconButton = true,
            } = this.props;

            const { locale, currency, userAgent, referrer, pageCtxId } = getContext();

            const availableCurrencies = supportedCurrencies.length
                ? supportedCurrencies
                : deserializeCurrencies(currencies);
            const currencyObj = getCurrencyObj(currency, currencies) || {};
            const currencyRate = selectedCurrencyRate || currencyObj.exchange_rate || null;
            const userBalance = parseBalance({
                balance: user.balance,
                supportedCurrencies: availableCurrencies,
                currencyRate,
                currency,
            });

            return {
                locale,
                displayedLocale,
                currency: currencyObj,
                supportedCurrencies: availableCurrencies,
                supportedLanguages: supportedLanguages.length ? supportedLanguages : deserializeLocales(locales),
                user: {
                    ...user,
                    balance: userBalance,
                },
                rollouts,
                notificationSoundEnabled,
                abTests,
                headerType,
                categories,
                showBuyerNavigation,
                userAgent,
                requestUrl,
                isFullWidth,
                referrer,
                pageCtxId,
                showNaviconButton,
            };
        }

        render() {
            return <Composite {...this.props} />;
        }
    }

    ContextWrapper.propTypes = {
        headerType,
        user,
        title,
        breadcrumbs,
        supportedCurrencies: localeSettings.supportedCurrencies,
        supportedLanguages: localeSettings.supportedLanguages,
        selectedCurrencyRate: localeSettings.currencyRate,
        locale: localeSettings.locale,
        locales,
        currencies,
        displayedLocale: localeSettings.displayedLocale,
        rollouts,
        notificationSoundEnabled,
        abTests,
        categories,
        showBuyerNavigation,
        userAgent,
        requestUrl,
        isFullWidth,
        showNaviconButton,
    };

    ContextWrapper.defaultProps = {
        supportedCurrencies: [],
        supportedLanguages: [],
        user: {},
        rollouts: {},
        abTests: {},
        isFullWidth: false,
        showNaviconButton: true,
    };

    ContextWrapper.childContextTypes = {
        locale: localeSettings.locale,
        displayedLocale: localeSettings.displayedLocale,
        supportedCurrencies: localeSettings.supportedCurrencies,
        supportedLanguages: localeSettings.supportedLanguages,
        rollouts,
        notificationSoundEnabled,
        user,
        abTests,
        headerType,
        categories,
        showBuyerNavigation,
        userAgent,
        requestUrl,
        isFullWidth,
        showNaviconButton,
        referrer,
        pageCtxId,
        currency: localeSettings.currency,
    };

    return ContextWrapper;
};

export default withContext;
