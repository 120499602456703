import { CurrencyConverter } from '@fiverr-private/futile';
import { currencyFormat } from '@fiverr-private/localization';

/**
 * Gets the session's currency object from the list of supported currencies.
 * @param {String} currency The session's currency.
 * @param {Currency[]} [currencies] A list of all supported currencies.
 * @return {Currency | null}
 */
export const getCurrencyObj = (currency, currencies = []) => {
    const sessionCurrencyMetadata = currencies.find(({ code }) => code === currency);

    return sessionCurrencyMetadata ?? null;
};

/**
 * De-serializes the currencies data fetched from layout phoenix into the format used by this package.
 * @param {Currency[]} currencies A list of all supported currencies.
 * @return {Object[]}
 */
export const deserializeCurrencies = (currencies = []) =>
    currencies.map(({ code, symbol, name, template }) => ({
        currency: code,
        currency_template: template,
        currency_symbol: symbol,
        currency_name: name,
    }));

/**
 * Parse user balance according to the current currency.
 * This function return a balance value formatted for React components,
 * it must be a string or undefined.
 * @param {String|Number|undefined} balance The balance to parse.
 * @param {{currency: String, currency_template: String}[]} supportedCurrencies Available currencies.
 * @param {String} currency Current currency.
 * @param {Number} currencyRate Current rate currency.
 * @returns {String}
 */
export const parseBalance = ({ balance, supportedCurrencies, currency, currencyRate }) => {
    if (balance === 0) {
        return;
    }

    if (!balance || !currencyRate) {
        return balance;
    }

    const { currency_template: template, currency_symbol: symbol } = supportedCurrencies.find(
        (supportedCurrency) => supportedCurrency.currency === currency
    );

    const fallbackValue = new CurrencyConverter({
        rate: currencyRate,
        template,
        symbol,
    }).convert(balance / 100);

    return currencyFormat({
        value: (balance / 100) * currencyRate,
        currencyCode: currency,
        options: {
            fallbackValue,
        },
    });
};
