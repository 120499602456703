import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Item from './Item';
import PopoverItems from './PopoverItems';
import PopoverItem from './PopoverItem';
import PopoverDivider from './PopoverDivider';
import VerticalDivider from './VerticalDivider';
import './style.scss';

const Nav = ({ alignToRight, displayFromSM, children, className }) => {
    const navClassName = classnames(
        'fiverr-nav',
        {
            'fiverr-nav-right': alignToRight,
            'display-from-sm': displayFromSM,
        },
        className
    );

    return (
        <nav className={navClassName}>
            <ul>{children}</ul>
        </nav>
    );
};

Nav.propTypes = {
    className: PropTypes.string,
    alignToRight: PropTypes.bool,
    displayFromSM: PropTypes.bool,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

Nav.defaultProps = {
    alignToRight: false,
    displayFromSM: false,
    children: null,
};

Nav.Item = Item;
Nav.PopoverItems = PopoverItems;
Nav.PopoverItem = PopoverItem;
Nav.PopoverDivider = PopoverDivider;
Nav.VerticalDivider = VerticalDivider;

export default Nav;
