import React from 'react';
import { I18nProvider } from '@fiverr-private/fiverr_context';
import { localeSettings } from '../../PropTypes';

/**
 * withTranslation HOC is responsible for passing the i18n prop in the correct
 * locale context to the given wrapped component.
 *
 * @param WrappedComponent
 * @returns {function(*, {locale: *}): *}
 */
const withTranslation = (WrappedComponent) => {
    const TranslationWrapper = (props, { locale }) => (
        <I18nProvider locale={locale}>{({ i18n }) => <WrappedComponent i18n={i18n} {...props} />}</I18nProvider>
    );

    TranslationWrapper.contextTypes = {
        locale: localeSettings.locale,
    };

    return TranslationWrapper;
};

export default withTranslation;
