import React from 'react';
import classnames from 'classnames';
import { string } from 'prop-types';
import { LocaleSelectionModalEntry, STYLES } from '@fiverr-private/locale_settings';
import { transformCurrencies } from '../../../utils/currency';
import { localeSettings, user, abTests, rollouts } from '../../../PropTypes';
import { HEADER_SOURCE, PACKAGE_CLASSNAME } from '../../../constants';
import { LINK_CLASSNAME, LINK_TITLE_CLASSNAME } from '../constants';

import '../LeanLocaleSettingsLink/style.scss';

const LocaleSettingsLink = (
    { style = STYLES.LEAN_MENU },
    { locale, displayedLocale, supportedLanguages, user, abTests, currency, supportedCurrencies, rollouts }
) => {
    const props = {
        locale,
        currency: currency?.code,
        displayedLocale,
        supportedCurrencies: transformCurrencies(supportedCurrencies),
        supportedLanguages,
        user,
        style,
        className: classnames(LINK_CLASSNAME),
        titleClassName: LINK_TITLE_CLASSNAME,
        modalClassName: PACKAGE_CLASSNAME,
        source: HEADER_SOURCE,
        abTests,
        rollouts,
    };

    return (
        <div className="lean-locale-settings">
            <LocaleSelectionModalEntry {...props} />
        </div>
    );
};

LocaleSettingsLink.propTypes = {
    className: string,
    style: string,
};

LocaleSettingsLink.contextTypes = {
    locale: localeSettings.locale,
    displayedLocale: localeSettings.displayedLocale,
    supportedLanguages: localeSettings.supportedLanguages,
    currency: localeSettings.currency,
    supportedCurrencies: localeSettings.supportedCurrencies,
    user,
    abTests,
    rollouts,
};

export default LocaleSettingsLink;
