import { startCase, isEmpty } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';

export const getBQData = ({ type, component = { name: 'Header' } }) => {
    const { pageCtxId } = getContext();

    const page = {
        ...(pageCtxId ? { ctx_id: pageCtxId } : {}),
        ...(!isEmpty(component) ? { component } : {}),
    };

    return {
        group: 'header_clicks',
        type: `clicked_header.${type}`,
        feature: {
            name: 'fiverr_business',
        },
        ...(!isEmpty(page) ? page : {}),
    };
};

export const getMPData = ({ eventName, role, target }) => {
    const data = {
        eventName,
    };

    if (role) {
        data.eventData = {
            'User Role': startCase(role),
        };
    }

    if (target) {
        data.Target = target;
    }

    return data;
};
