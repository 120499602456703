import React from 'react';
import classnames from 'classnames';
import { PACKAGE_CLASS, FIT_PACKAGE_CLASS } from '../../../lib/constants';
import withContext from '../withContext';

const withEntry = (Component) => {
    const Entry = ({ className, ...props }: { className?: string }) => {
        const wrapperClass = classnames(className, FIT_PACKAGE_CLASS, PACKAGE_CLASS);

        return <Component className={wrapperClass} {...props} />;
    };

    return withContext(Entry);
};

export default withEntry;
