import { bool, string, number, node, shape, arrayOf, oneOfType, oneOf, object } from 'prop-types';
import { TYPES, INTEGRATIONS } from '../constants';

export const renderable = oneOfType([arrayOf(node), node]);

export const abTests = object;

export const headerType = oneOf(Object.values(TYPES));

export const isTransparent = bool;

export const notificationSoundEnabled = bool;

export const locale = string;

export const user = shape({
    id: number,
    fullName: string,
    username: string,
    business: shape({ organizationId: string, role: string }),
    profileImage: string,
    isOnline: bool,
    isPending: bool,
    isActive: bool,
    isPro: bool,
    isSeller: bool,
    isVid: bool,
    businessOfferEnrollment: bool,
    isReferalEligible: bool,
    isRnc: bool,
    makersProjectEligible: bool,
    dynamicMatchingEligibility: bool,
    promotedAdsVisible: bool,
    sellerPlus: shape({
        status: string,
        tier: string,
    }),
    balance: oneOfType([string, number]),
});

export const categories = arrayOf(
    shape({
        id: number,
        subCategories: arrayOf(
            shape({
                id: number,
                isNew: bool,
            })
        ),
    })
);

export const rollouts = object;

export const breadcrumbs = shape({
    crumbs: arrayOf(string),
    current: number,
});

export const title = string;

// Based on src/lib/currencySerializers/types.d.ts
const currencyType = shape({
    code: string,
    template: string,
    symbol: string,
    name: string,
    exchange_rate: number,
});

export const localeSettings = {
    locale: string,
    displayedLocale: string,
    currency: currencyType,
    currencyRate: number,
    supportedCurrencies: arrayOf(
        shape({
            currency: string,
            currency_symbol: string,
            currency_name: string,
        })
    ),
    supportedLanguages: arrayOf(string),
    style: string,
    source: string,
    theme: string,
};

export const currencies = arrayOf(currencyType);

export const locales = arrayOf(
    shape({
        id: string,
        iso_code: string,
        name: string,
        native: string,
        language_code: string,
        country_code: string,
    })
);

export const isPro = bool;

export const isBusiness = bool;

export const business = shape({
    organizationId: string,
    role: string,
});

export const showBuyerNavigation = bool;

export const userAgent = string;

export const fill = string;

export const requestUrl = string;

export const isFullWidth = bool;

export const showNaviconButton = bool;

export const referrer = string;

export const pageCtxId = string;

export const logoMakerProps = {
    integration: oneOf([
        INTEGRATIONS.ADALO,
        INTEGRATIONS.ELEMENTOR,
        INTEGRATIONS.WORDPRESS,
        INTEGRATIONS.W3SCHOOLS,
        INTEGRATIONS.WOO,
        INTEGRATIONS.NONE,
    ]),
    isTransparent: bool,
    showFiverrLink: bool,
    showSeoLinks: bool,
    showBackButton: bool,
};
