import { CURRENCY_STYLES } from '../../currency/constants';
import { getCurrencySymbol } from '../currencySymbol';
import { FORMATTING_PARTS } from './constants';

const isNumeric = (c) => /\d/.test(c);
const isSpace = (c) => /\s/.test(c);
const isPercentage = (c) => c === '%';
const isMinusSign = (c) => /—|−|-|–|\(|\)/.test(c);

const buildIntegerPart = (num) => ({ type: FORMATTING_PARTS.INTEGER, value: num });
const buildLiteralPart = (char) => ({ type: FORMATTING_PARTS.LITERAL, value: char });
const buildPercentagePart = (char) => ({ type: FORMATTING_PARTS.PERCENTAGE, value: char });
const buildMinusSignPart = (char) => ({ type: FORMATTING_PARTS.MINUS_SIGN, value: char });
const buildDecimalPart = (char) => ({ type: FORMATTING_PARTS.DECIMAL, value: char });
const buildCurrencyPart = (currency) => ({ type: FORMATTING_PARTS.CURRENCY, value: currency });
const buildFractionPart = (fraction) => ({ type: FORMATTING_PARTS.FRACTION, value: fraction });

/**
 * Take a formatted string of number or currency and returns it split into an array,
 * almost the same as the non-stable Intl method - formatToParts.
 * We are making 2 iterations (one for the left/integer side and one for the right/fractions side)
 * and then we are concatenating those 2 arrays.
 * Example:
 * 'US$1,234.5' -> [
 *      { type: 'currency', value: 'US$'},
 *      { type: 'integer', value: '1,234'},
 *      { type: 'decimal', value: '.'},
 *      { type: 'fraction', value: '5'},
 * ]
 * @param {Object} options - should include whatever is needed by those 2 iterations
 * @returns {Array}
 */
const toParts = (options) => [...toPartsInteger(options), ...toPartsFraction(options)];

/**
 * Iterates over the formattedString and splits it into an array of different types.
 * This iteration ends when we get the integer type
 * @param {Object} options
 * @returns {Array} - Array of parts, starting from the left side (beginning) of the string until the integer.
 */
const toPartsInteger = (options) => {
    const { localizationContext, parsedOptions, formattingLocale, currencyCode, formattedString, formattedNumber } =
        options;
    const parts: { type: string | undefined; value: number }[] = [];

    let i = 0;
    while (i < formattedString.length) {
        const char = formattedString.charAt(i);
        if (isNumeric(char)) {
            /*
            If the char is numeric it means that this is the last char i need to iterate over.
            This is because the last part of the 'integer' side of the formatted string is the integer
            When we get to the first numeric character we are building the integer using
            the formattedNumber and Intl.
             */
            const integer = Math.abs(parseInt(formattedNumber, 10));
            const integerOptions = {
                minimumFractionDigits: 0,
                maximumFractionDigits: 0,
            };
            parts.push(buildIntegerPart(new Intl.NumberFormat(formattingLocale, integerOptions).format(integer)));
            break;
        } else if (isSpace(char)) {
            parts.push(buildLiteralPart(char));
        } else if (isPercentage(char)) {
            parts.push(buildPercentagePart(char));
        } else if (isMinusSign(char)) {
            parts.push(buildMinusSignPart(char));
        } else {
            /*
            We get here if the character is not numeric, not a white space, not a percentage and not a minus.
            This means that it must be the currency symbol / code.
            In this case we are using a util to understand what is the code / symbol we should add
            (instead of using the formattedString itself, because it's impossible due to spaces etc)
            Then we moving our iterator according to the length of the currency symbol we added so we
            keep iterating over the formattedString
             */
            const currencySymbol =
                parsedOptions.currencyDisplay === CURRENCY_STYLES.CODE
                    ? currencyCode
                    : getCurrencySymbol({ localizationContext, currencyCode, formattingLocale });
            parts.push(buildCurrencyPart(currencySymbol));
            i = i + currencySymbol.length;
            continue;
        }
        i++;
    }

    return parts;
};

/**
 * Iterates over the formattedString and splits it into an array of different types.
 * This iteration ends when we get to the fraction type (starts from the end)
 * @param {Object} options
 * @returns {Array} - Array of parts, starting from the right side (end) of the string until the fraction digits.
 */
const toPartsFraction = (options) => {
    const { localizationContext, formattingLocale, currencyCode, formattedString, parsedOptions } = options;
    const parts: { type: string | undefined; value: string }[] = [];
    const decimalValue = (1.1).toLocaleString(formattingLocale).substring(1, 2);
    const hasFractionDigits = formattedString.indexOf(decimalValue) !== -1;

    let i = formattedString.length - 1;
    while (i >= 0) {
        const char = formattedString.charAt(i);
        if (isNumeric(char)) {
            /*
            If the char is numeric it means that this is the last char i need to iterate over.
            If the origin number has no fraction digits it means we get to the integer so we should break,
            because it is handled by the other method (toPartsInteger).
            If the origin number has fraction digits we should add those fraction digits to the parts array.
            We should break because this is the last part of the 'fraction' side of the formatted string
            When we get to the first numeric character we are building the fraction number using an internal loop
            until we getting a char that is not numeric.
            Then we are adding manually the decimal sign (we cannot continue iterating because we will end up
            with a non-numeric char and in the ELSE (treated as currency)
             */
            if (hasFractionDigits) {
                let fraction = char;
                let nextIndex = i - 1;
                let nextChar = formattedString.charAt(nextIndex);
                while (isNumeric(nextChar)) {
                    fraction = `${nextChar}${fraction}`;
                    nextIndex--;
                    nextChar = formattedString.charAt(nextIndex);
                }
                parts.unshift(buildFractionPart(fraction));
                parts.unshift(buildDecimalPart(decimalValue));
            }
            break;
        } else if (isSpace(char)) {
            parts.unshift(buildLiteralPart(char));
        } else if (isPercentage(char)) {
            parts.unshift(buildPercentagePart(char));
        } else if (isMinusSign(char)) {
            parts.unshift(buildMinusSignPart(char));
        } else {
            /*
            We get here if the character is not numeric, not a white space, not a percentage and not a minus.
            This means that it must be the currency symbol / code.
            In this case we are using a util to understand what is the code / symbol we should add
            (instead of using the formattedString itself, because it's impossible due to spaces etc)
            Then we moving our iterator according to the length of the currency symbol we added so we
            keep iterating over the formattedString
             */
            const currencySymbol =
                parsedOptions.currencyDisplay === CURRENCY_STYLES.CODE
                    ? currencyCode
                    : getCurrencySymbol({ localizationContext, currencyCode, formattingLocale });
            parts.unshift(buildCurrencyPart(currencySymbol));
            i = i - currencySymbol.length;
            continue;
        }
        i--;
    }

    return parts;
};

export { toParts, FORMATTING_PARTS };
