import { logger } from '@fiverr-private/obs';
import { DEFAULT_LOCALIZATION_CONTEXT, localizationContextParams } from '../../../shared/constants';
import { validateOptions } from '../utils/validateOptions';
import { ERROR_MESSAGES } from '../constants';
import { DATETIME_FORMATTING_OPTIONS, DATETIME_ELEMENT_STYLES } from './constants';

interface dateTimeFormatOptions {
    fallbackValue?: string;
    formattingLocale?: string;
    year?: string;
    month?: string;
    day?: string;
    hour?: string;
    minute?: string;
    second?: string;
    dateStyle?: string;
    hour12?: boolean;
    weekday?: string;
}

interface dateTimeFormatParams {
    localizationContext?: localizationContextParams;
    value: Date;
    options?: dateTimeFormatOptions;
}

/**
 * Format a date / time.
 * Use this to format dates and times.
 * Return the date / time as a string, formatted to user formatting locale.
 * @param {Object} localizationContext - the localization context that is being injected
 * dynamically according to the run time env by one of the wrappers - withBrowserContext / withMainContext / withModuleContext
 * @param {Date | string} value date to be formatted.
 * @param {Object} [options={}] Optional formatting configurations.
 * @returns {string} Formatted value string.
 */
const dateTimeFormat = ({ localizationContext = {}, value, options = {} }: dateTimeFormatParams) => {
    const fallbackLocale = localizationContext?.formattingLocale || DEFAULT_LOCALIZATION_CONTEXT.formattingLocale;
    const fallbackValue =
        options.fallbackValue ||
        new Intl.DateTimeFormat(fallbackLocale, {
            year: DATETIME_ELEMENT_STYLES.NUMERIC,
            month: DATETIME_ELEMENT_STYLES.SHORT,
            day: DATETIME_ELEMENT_STYLES.TWO_DIGIT,
            hour: DATETIME_ELEMENT_STYLES.TWO_DIGIT,
            minute: DATETIME_ELEMENT_STYLES.TWO_DIGIT,
        }).format(value);

    try {
        if (Intl === undefined) {
            logger.warn(ERROR_MESSAGES.INTL_IS_NOT_DEFINED);
            return fallbackValue;
        }

        if (!localizationContext || Object.keys(localizationContext).length === 0) {
            return fallbackValue;
        }

        const formattingLocale = options.formattingLocale || localizationContext.formattingLocale;
        const parsedOptions = parseOptions(options);
        return new Intl.DateTimeFormat(formattingLocale, parsedOptions).format(value);
    } catch (e) {
        logger.error(e as Error, { value, options, formatter: 'dateTimeFormat' });
        return fallbackValue;
    }
};

/**
 * Parse formatter options.
 * This method validates the properties in the options object and overrides properties for Fiverr custom formatting rules.
 * @param {Object} options Optional formatting configurations.
 * @returns {Object} Validated formatting configurations.
 */
const parseOptions = (options) => validateOptions(DATETIME_FORMATTING_OPTIONS, options);

export { DATETIME_ELEMENT_STYLES, dateTimeFormat, parseOptions };
