import { Domingo } from '@fiverr-private/domingo';
import { getContext } from '@fiverr-private/fiverr_context';
import { HEADER_POPUP_SOURCE } from '../../constants';

const COMMON_QUERY = {
    query: { source: HEADER_POPUP_SOURCE },
};

const getBaseUrl = () => {
    const { url } = getContext();
    return Domingo(url).removePath();
};

export const getProUrl = () => `${getBaseUrl().pro().url}?source=${HEADER_POPUP_SOURCE}`;

export const getProFreelancerUrl = () =>
    getBaseUrl().removePro().path('content_pages_page', { page_slug: 'pro-freelancers' }, COMMON_QUERY).url;
