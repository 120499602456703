import { RefObject, useEffect } from 'react';
import useLatest from '../useLatest';
import { HandledEvents, PossibleEvent } from './types';

export const events: HandledEvents = ['mousedown', 'touchstart'];

const useOnClickOutside = (ref: RefObject<HTMLElement>, handler?: (event: PossibleEvent) => void) => {
    const handlerRef = useLatest(handler);

    useEffect(() => {
        if (!handler || typeof document === 'undefined') {
            return;
        }

        const listener = (event: PossibleEvent) => {
            if (!ref.current || !handlerRef.current || ref.current.contains(event.target as Node)) {
                return;
            }

            handlerRef.current(event);
        };

        events.forEach((event) => {
            document.addEventListener(event, listener);
        });

        return () => {
            events.forEach((event) => {
                document.removeEventListener(event, listener);
            });
        };
    }, [handler, handlerRef, ref]);
};

export default useOnClickOutside;
