import React, { useContext } from 'react';
import classnames from 'classnames';
import { noop } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { Icon } from '@fiverr-private/fit';
import { r_globe } from '@fiverr-private/fit/icons';
import Context from '../../contexts/app';
import { language } from '../../lib/types';
import {
    DEFAULT_LANGUAGE,
    LANGUAGE_CONFIG_MAP,
    STYLES,
    LOCALE_SETTINGS_MODAL_TEST_ID,
    ALLOCATIONS,
} from '../../lib/constants';

import './style.scss';

export interface LanguageSelectionTriggerProps {
    language?: language;
    style?: string;
    onTrigger?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    className?: string;
    onLanguageSelectionOpen?: () => void;
}

const LanguageSelectionTrigger = ({
    language = DEFAULT_LANGUAGE,
    style = STYLES.DEFAULT,
    onTrigger = noop,
    className,
    onLanguageSelectionOpen = noop,
}: LanguageSelectionTriggerProps) => {
    const { titleClassName } = useContext(Context);
    const { abTests = {}, userId } = getContext();
    const hideTitle = !userId && abTests[LOCALE_SETTINGS_MODAL_TEST_ID] === ALLOCATIONS.TEST_C;
    const { label } = LANGUAGE_CONFIG_MAP[language];
    const resolvedStyle = Object.values(STYLES).includes(style) ? style : STYLES.DEFAULT;
    const wrapperClass = classnames(
        'selection-trigger',
        'language-selection-trigger',
        'text-body-2',
        className,
        titleClassName,
        resolvedStyle,
        { 'hide-title': hideTitle }
    );

    const onClickHandler = (event) => {
        onTrigger(event);
        onLanguageSelectionOpen();
    };

    return (
        <button className={wrapperClass} onClick={onClickHandler}>
            <Icon className="trigger-icon" size={16}>
                {r_globe}
            </Icon>
            {<span className="label">{label}</span>}
        </button>
    );
};

export default LanguageSelectionTrigger;
