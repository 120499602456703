import _ from 'lodash';
import { sendEvents } from '@fiverr-private/futile';
import { bigQuery } from '@fiverr-private/obs';
import { biEnrichmentParams } from '../../../lib/types';
import { ACTIONS_GROUP, ACTIONS_SUB_GROUP, CLICK_ACTION_TYPE, DOMAIN_NAME } from './constants';

/**
 * Sends event to BQ.
 * If Fiverr.sendClientEvents exists globally, will send with futile, else with obs.
 * @param {Object} data - to send to BigQuery.
 */
export const sendBigQueryEvent = (data) => {
    if (typeof global.Fiverr === 'object' && typeof global.Fiverr.sendClientEvents === 'function') {
        sendEvents(data);
    } else {
        bigQuery.send(data);
    }
};

export const createBigQueryEvent =
    (type) =>
    (params = {}) => {
        const baseParams = buildBaseParams(type);
        const enrichedParams = buildEnrichedParams(params);

        return _.merge({}, baseParams, enrichedParams);
    };

const buildBaseParams = (type) => ({
    type,
    group: ACTIONS_GROUP,
    sub_group: ACTIONS_SUB_GROUP,
    action: {
        type: CLICK_ACTION_TYPE,
    },
    domain: {
        name: DOMAIN_NAME,
    },
});

const buildEnrichedParams = ({ source, user, language, currency, browserLanguage }: biEnrichmentParams) => ({
    user: _.merge({}, user || {}, {
        locale: language,
        display_currency: currency,
    }),
    event_source: source || '',
    ...(browserLanguage && {
        page: {
            element: {
                metadata: browserLanguage,
            },
        },
    }),
});
