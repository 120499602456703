import { ReactElement } from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { injectTemplates, TemplatesParam } from '../../injectTemplates';

interface I18nParams {
    /**
     * The translation key to use.
     */
    k: string | string[];

    /**
     * The locale to translate to.
     */
    locale?: string;

    /**
     * Named templates that should be injected into the translation.
     */
    templates?: TemplatesParam;

    /**
     * Interpolated parameters object.
     */
    params?: object;
}

export const I18n = ({ k, templates = {}, params = {}, locale: localeFromProps }: I18nParams): ReactElement | null => {
    if (!k) {
        return null;
    }

    const { locale: localeFromContext } = getContext();
    const locale = localeFromProps || localeFromContext;
    let output;

    try {
        output = i18n.t(k, { ...params, $scope: locale });
    } catch (e) {
        output = k;
    }

    return injectTemplates(output, templates);
};
