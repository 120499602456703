import { useRef } from 'react';
import useIsomorphicLayoutEffect from '../useIsomorphicLayoutEffect';

const useLatest = <T>(value: T) => {
    const ref = useRef(value);

    useIsomorphicLayoutEffect(() => {
        ref.current = value;
    });

    return ref;
};

export default useLatest;
