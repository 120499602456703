import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import pathfinder from '@fiverr-private/pathfinder';
import FiverrLogo from '@fiverr-private/fit/icons/logos/fiverr_logo_nav';
import { LOGO_BASE_CLASS, LOGO_BASE_PATH } from '../../constants';

import './style.scss';

export const Logo = ({ path = LOGO_BASE_PATH, className }) => {
    const finalClassName = classnames(LOGO_BASE_CLASS, className);

    return (
        <a href={path} className={finalClassName}>
            <FiverrLogo />
        </a>
    );
};

Logo.propTypes = {
    path: PropTypes.string,
    className: PropTypes.string,
};

export const LogoSeller = ({ username }) => {
    const path = pathfinder('user_seller_dashboard_index', {
        user_id: username,
    });

    return <Logo path={path} />;
};

LogoSeller.propTypes = {
    username: PropTypes.string.isRequired,
};
