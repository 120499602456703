/**
 * Base big query parameters to be reported for every link click event.
 *
 * @type {Object}
 */
export const BASE_BIG_QUERY_PARAMS = {
    entity: 'client',
    page: {
        element: {
            type: 'button',
        },
    },
    action: {
        type: 'click',
    },
    out_of_session: false,
};
