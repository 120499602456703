import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import './style.scss';

function Row({ wrapperClassName, className, children, rowRef }) {
    const rowWrapperClassName = classnames('header-row-wrapper', wrapperClassName);

    const rowClassName = classnames('header-row', 'max-width-container', 'equal-padding', className);

    return (
        <div className={rowWrapperClassName} ref={rowRef}>
            <div className={rowClassName}>{children}</div>
        </div>
    );
}

Row.propTypes = {
    wrapperClassName: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    rowRef: PropTypes.func,
};

Row.defaultProps = {
    wrapperClassName: null,
    className: null,
    children: null,
    rowRef: () => true,
};

export default Row;
