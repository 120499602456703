export const SUPPORTED_FORMATTING_LOCALES_MAP = {
    'en-US': 'English (United States)',
    'en-GB': 'English (United Kingdom)',
    'en-AU': 'English (Australia)',
    'en-CA': 'English (Canada)',
    'de-DE': 'Deutsch (Deutschland)',
    'de-AT': 'Deutsch (Österreich)',
    'es-ES': 'Español (España)',
    'es-MX': 'Español (México)',
    'fr-FR': 'Français (France)',
    'fr-CA': 'Français (Canada)',
    'it-IT': 'Italiano (Italia)',
    'nl-NL': 'Nederlands (Nederland)',
    'pt-BR': 'Português (Brasil)',
    'pt-PT': 'Português (Portugal)',
};

export const FORMATTING_LOCALE_FALLBACK_MAP = {
    'es-US': 'es-MX',
    'es-AR': 'es-MX',
    'es-BO': 'es-MX',
    'es-CL': 'es-MX',
    'es-CO': 'es-MX',
    'es-CR': 'es-MX',
    'es-DO': 'es-MX',
    'es-EC': 'es-MX',
    'es-GT': 'es-MX',
    'es-HN': 'es-MX',
    'es-NI': 'es-MX',
    'es-PA': 'es-MX',
    'es-PE': 'es-MX',
    'es-PR': 'es-MX',
    'es-PY': 'es-MX',
    'es-SV': 'es-MX',
    'es-UY': 'es-MX',
    'es-VE': 'es-MX',
    es: 'es-ES',
};

export const DEFAULT_FORMATTING_LOCALE = 'en-GB';
