export enum EventSources {
    language = 'language',
    currency = 'currency',
    locale = 'locale',
}

export interface EventType {
    [EventSources.language]: LanguageEventSource;
    [EventSources.currency]: EventSourceBase;
    [EventSources.locale]: LocaleEventSource;
}

export type EventSource = EventSourceBase | LanguageEventSource | LocaleEventSource;

export interface EventSourceBase {
    modal: {
        triggered: string;
        opened: string;
        closed: string;
    };
    update: {
        success: string;
        fail: string;
    };
}

export interface LanguageEventSource extends EventSourceBase {
    localeMessage: {
        show: string;
        update: string;
        closed: string;
        clicked: string;
    };
}

export interface LocaleEventSource extends EventSourceBase {
    tab: {
        currency: string;
        language: string;
    };
}

export const EventTypes: EventType = {
    [EventSources.language]: {
        modal: {
            triggered: 'language_modal_triggered',
            opened: 'language_modal_opened',
            closed: 'language_modal_closed',
        },
        update: {
            success: 'language_update_success',
            fail: 'language_update_fail',
        },
        localeMessage: {
            show: 'suggest_locale_change_notification_show',
            update: 'locale_message_language_update_success',
            closed: 'suggest_locale_change_notification_close',
            clicked: 'clicked_on_suggest_locale_change_notification',
        },
    },
    [EventSources.currency]: {
        modal: {
            triggered: 'currency_modal_triggered',
            opened: 'currency_modal_opened',
            closed: 'currency_modal_closed',
        },
        update: {
            success: 'currency_update_success',
            fail: 'currency_update_fail',
        },
    },
    [EventSources.locale]: {
        modal: {
            triggered: 'locale_settings_modal_triggered',
            opened: 'locale_settings_modal_opened',
            closed: 'locale_settings_modal_closed',
        },
        tab: {
            currency: 'clicked_on_currency_settings',
            language: 'clicked_on_language_settings',
        },
        update: {
            success: 'locale_settings_update_success',
            fail: 'locale_settings_update_fail',
        },
    },
};
