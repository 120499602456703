import { sendEvents } from '@fiverr-private/futile';
import { bigQuery } from '@fiverr-private/obs';

/**
 * Sends event to BQ.
 * If Fiverr.sendClientEvents exists globally, will send with futile, else with obs.
 * @param {Object} data - to send to BigQuery.
 */
export const sendBigQueryEvent = (data) => {
    if (typeof globalThis.Fiverr === 'object' && typeof globalThis.Fiverr.sendClientEvents === 'function') {
        // TODO: remove when fully migrated to layout
        sendEvents(data);
    } else {
        bigQuery.send(data);
    }
};
