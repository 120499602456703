interface validateFormattingConfig {
    [key: string]: any;
}

/**
 * Validate the formatter options.
 * This function removes all unsupported properties from the 'options' object, while validating
 * the supported options.
 * @param {Object} CONFIG_MAP Mapping of all possible formatter configurations.
 * @param {Object} options Formatter options object to be validated.
 * @returns {Object} Validated formatter options.
 */
export const validateOptions = (CONFIG_MAP: validateFormattingConfig, options: validateFormattingConfig): any => {
    const validated = {};

    // Assign all default values
    for (const [prop, value] of Object.entries(CONFIG_MAP)) {
        if (value.defaultValue) {
            validated[value.mapTo || prop] = value.defaultValue;
        }
    }

    for (const [inputProp, value] of Object.entries(options)) {
        // Check option key is whitelisted in CONFIG_MAP
        if (CONFIG_MAP[inputProp]) {
            const outputProp = CONFIG_MAP[inputProp].mapTo || inputProp;
            validated[outputProp] = value;

            // Assign validatorFallbackValue if validation function exists and fails
            const validator = CONFIG_MAP[inputProp].validator;
            if (validator && !validator(value)) {
                validated[outputProp] = CONFIG_MAP[inputProp].validatorFallbackValue;
            }
        }
    }

    return validated;
};
