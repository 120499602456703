import { WindowModalMethodName } from '../../types';
import { DEFAULT_ATTEMPTS, DEFAULT_DELAY } from './constants';

export const waitForWindowMethod = (methodName: WindowModalMethodName) =>
    new Promise<void>((resolve, reject) => {
        const hasMethod = () => typeof window !== 'undefined' && typeof window[methodName] === 'function';

        if (hasMethod()) {
            resolve();
            return;
        }

        let currentAttempt = 0;

        const interval = setInterval(() => {
            if (currentAttempt >= DEFAULT_ATTEMPTS) {
                clearInterval(interval);
                reject();
                return;
            }

            if (hasMethod()) {
                clearInterval(interval);
                resolve();
            }

            currentAttempt++;
        }, DEFAULT_DELAY);
    });
