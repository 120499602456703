import {
    triggerSignIn as triggerSignInBase,
    triggerSignUp as triggerSignUpBase,
    type ExternalTriggerParams,
} from '@fiverr-private/identification_core';

const triggerSignIn = (params?: ExternalTriggerParams) => triggerSignInBase(params);
const triggerSignUp = (params?: ExternalTriggerParams) => triggerSignUpBase(params);

export { triggerSignIn, triggerSignUp, type ExternalTriggerParams };
