export const CURRENCY_STYLES = {
    CODE: 'code',
    SYMBOL: 'symbol',
};

export const CURRENCY_FORMATTING_OPTIONS = {
    style: {
        mapTo: 'currencyDisplay',
        validator: (value) => Object.values(CURRENCY_STYLES).includes(value),
        validatorFallbackValue: CURRENCY_STYLES.SYMBOL,
        defaultValue: CURRENCY_STYLES.SYMBOL,
    },
    minimumFractionDigits: {},
    maximumFractionDigits: {},
};
