import { tokens, type Types } from '@fiverr-private/theme';
import type { BaseButtonProps } from './types';

export const getStylingProps = (
    variant: BaseButtonProps['variant'],
    loading: BaseButtonProps['loading'],
    disabled: BaseButtonProps['disabled'],
    colorScheme: BaseButtonProps['colorScheme'],
    shape: BaseButtonProps['shape'],
    intent: BaseButtonProps['intent'],
    outlineColor: BaseButtonProps['outlineColor']
): Types.BoxType => ({
    cursor: disabled ? undefined : 'pointer',
    display: 'inlineFlex',
    justifyContent: 'center',
    alignItems: 'center',
    gap: '2',
    boxSizing: 'borderBox',
    position: 'relative',
    borderWidth: 'sm',
    borderStyle: 'solid',
    fontWeight: 'semibold',
    decoration: { default: 'none', hover: 'none' },
    userSelect: 'none',
    outlineOffset: { focusVisible: 'sm' },
    outlineStyle: { focusVisible: 'solid' },
    outlineWidth: { focusVisible: 'md' },
    borderRadius: shape === 'circle' ? 'circle' : 'lg',
    borderColor: 'transparent',
    pointerEvents: disabled || loading ? 'none' : undefined,
    style: {
        transition: `${tokens.animationDuration.fast1} ${tokens.animationTiming.easeInOut}`,
    },
    ...(colorScheme === 'white' && {
        outlineColor: {
            focusVisible: outlineColor || 'white',
        },
        ...(variant === 'outline' && {
            borderColor: disabled ? 'white_50' : 'white',
        }),
        ...(variant === 'filled' && {
            backgroundColor: disabled ? 'white' : { default: 'white', hover: 'white_90', active: `white_80` },
            color: disabled ? 'grey_600' : { default: 'grey_1200', hover: 'grey_1200' },
        }),
        ...((variant === 'outline' || variant === 'ghost') && {
            backgroundColor: disabled ? undefined : { default: 'transparent', hover: 'white_10', active: 'white_20' },
            color: disabled ? 'white_50' : 'white',
        }),
    }),
    ...(colorScheme &&
        colorScheme !== 'white' && {
            outlineColor: {
                focusVisible: outlineColor || `${colorScheme}_900`,
            },
            ...(variant === 'outline' && {
                borderColor: disabled ? `${colorScheme}_300` : `${colorScheme}_900`,
            }),
            ...(variant === 'filled' && {
                backgroundColor: disabled
                    ? `${colorScheme}_300`
                    : { default: `${colorScheme}_900`, hover: `${colorScheme}_1000`, active: `${colorScheme}_1100` },
                color: { default: 'white', hover: 'white' },
            }),
            ...((variant === 'outline' || variant === 'ghost') && {
                backgroundColor: disabled
                    ? undefined
                    : { default: `transparent`, hover: `${colorScheme}_100`, active: `${colorScheme}_200` },
                color: disabled ? `${colorScheme}_300` : `${colorScheme}_900`,
            }),
        }),
    ...((intent === 'primary' || intent === 'secondary') && {
        outlineColor: {
            focusVisible: outlineColor || `grey_1200`,
        },
        ...((variant === 'outline' || variant === 'ghost') && {
            backgroundColor: { hover: 'grey_200', active: 'grey_300' },
            color: disabled ? 'grey_800' : 'grey_1200',
        }),
    }),
    ...(intent === 'primary' && {
        ...(variant === 'outline' && {
            borderColor: disabled ? `grey_300` : `grey_1200`,
        }),
        ...(variant === 'filled' && {
            backgroundColor: disabled ? `grey_300` : { default: 'grey_1200', hover: 'grey_1100', active: 'grey_1000' },
            color: disabled ? 'grey_800' : { default: 'white', hover: 'white' },
        }),
    }),
    ...(intent === 'secondary' && {
        ...(variant === 'outline' && {
            borderColor: disabled ? `grey_300` : `grey_500`,
        }),
        ...(variant === 'filled' && {
            backgroundColor: disabled ? `grey_300` : { default: 'grey_300', hover: 'grey_400', active: 'grey_500' },
            color: disabled ? 'grey_800' : 'grey_1200',
        }),
    }),
});
