export const FILE_SIZE_FORMATTING_OPTIONS = {
    maximumFractionDigits: {
        defaultValue: 2,
    },
};

export const K = 1024;

export const FILE_SIZE_TEMPLATES = [
    (fileSize) => `${fileSize} byte`,
    (fileSize) => `${fileSize} kB`,
    (fileSize) => `${fileSize} MB`,
    (fileSize) => `${fileSize} GB`,
    (fileSize) => `${fileSize} TB`,
    (fileSize) => `${fileSize} PB`,
    (fileSize) => `${fileSize} EB`,
    (fileSize) => `${fileSize} ZB`,
    (fileSize) => `${fileSize} YB`,
];
