import { numberFormat as numberFormatBase, NUMBER_STYLES } from '../utils/formatters/number';
import { currencyFormat as currencyFormatBase, CURRENCY_STYLES } from '../utils/formatters/currency';
import { dateTimeFormat as dateTimeFormatBase, DATETIME_ELEMENT_STYLES } from '../utils/formatters/dateTime';
import { fileSizeFormat as fileSizeFormatBase } from '../utils/formatters/fileSize';
import { getCurrencySymbol as getCurrencySymbolBase } from '../utils/formatters/utils/currencySymbol';
import { withBrowserContext, getLocalizationContext } from '../withBrowserContext';

export const numberFormat = withBrowserContext(numberFormatBase);
export const currencyFormat = withBrowserContext(currencyFormatBase);
export const dateTimeFormat = withBrowserContext(dateTimeFormatBase);
export const fileSizeFormat = withBrowserContext(fileSizeFormatBase);
export const getCurrencySymbol = withBrowserContext(getCurrencySymbolBase);
export * from '../components';
export { NUMBER_STYLES, CURRENCY_STYLES, DATETIME_ELEMENT_STYLES, getLocalizationContext };
export * from '../utils/currencies';
export * from '../utils/buyerAssumedLanguage';
export {
    SUPPORTED_FIVERR_LOCALES,
    SUPPORTED_FIVERR_LOCALES_KEYS,
    DEFAULT_FIVERR_LOCALE,
    DEFAULT_FIVERR_LANGUAGE,
    LOCALE_TO_SUBDOMAIN_MAP,
    SUPPORTED_FIVERR_LANGUAGES,
    isSupportedFiverrLocale,
    getFiverrLocaleNativeName,
    replaceLocaleSubdomain,
} from '../utils/supportedFiverrLocales';
export {
    SUPPORTED_FORMATTING_LOCALES,
    SUPPORTED_FORMATTING_LOCALES_KEYS,
    calculateFormattingLocale,
    isSupportedFormattingLocale,
    getFormattingLocaleNativeName,
    getFiverrLocaleByFormattingLocale,
    getFiverrLocaleByLanguageCode,
} from '../utils/supportedFormattingLocales';
export * from '../utils/shortLanguageCode';
export * from '../utils/supportedSellerLanguages';
export * from '../utils/sellerValidatedLanguages';
export * from '../utils/sellerValidatedLanguages/constants';
