import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import Context from '../../../contexts/app';
import { DEFAULT_LANGUAGE } from '../../../lib/constants';
import { localeSettings } from '../../../lib/types';

const withContext = (Component) => {
    const AppContext = (props: localeSettings) => {
        const { titleClassName, itemClassName, displayedLocale, source, user, currency } = props;

        const { locale } = getContext();

        const language = displayedLocale || locale || DEFAULT_LANGUAGE;
        const value = {
            titleClassName,
            itemClassName,
            language,
            source,
            user,
            currency,
        };

        return (
            <Context.Provider value={value}>
                <Component {...props} />
            </Context.Provider>
        );
    };

    return AppContext;
};

export default withContext;
