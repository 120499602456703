import { logger } from '@fiverr-private/obs';
import fiverrDomains from './url-structures.json';
import { UrlKeys } from './types';

export const transformUrl = (url: string, key: UrlKeys, value?: string) => {
    try {
        for (const { from, to } of fiverrDomains) {
            const regExp = new RegExp(from);
            const match = url.match(regExp);
            const isMatchingTemplate = match && match[0] === url;
            if (isMatchingTemplate) {
                const toKey = value ? to[key].replace(`$<${key}>`, value) : to[key];

                return url.replace(regExp, toKey);
            }
        }
        throw new Error(`Url does not match any domain template. URL: ${url}. METHOD: ${key}`);
    } catch (error) {
        logger.error(error as Error, {
            message: `[Domingo] Error while transforming url. ${(error as Error).message}`,
            url,
            method: key,
        });
        return url;
    }
};

export default transformUrl;
