import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { atoms } from '@fiverr-private/theme';
import BaseButton from '../BaseButton';
import type { ButtonProps, ButtonSize } from './types';

const MAP_SIZE_TO_CLASS: { [key in ButtonSize]: string } = {
  sm: atoms({
    paddingY: '1.5',
    paddingX: '3',
    lineHeight: 'b_sm',
    fontSize: 'b_sm',
  }),
  md: atoms({
    paddingY: '2',
    paddingX: '4',
    lineHeight: 'b_md',
    fontSize: 'b_md',
  }),
  lg: atoms({
    paddingY: '2.5',
    paddingX: '5',
    lineHeight: 'b_lg',
    fontSize: 'b_md',
  }),
};

const Button = forwardRef<HTMLButtonElement | HTMLAnchorElement, ButtonProps>(
  (
    {
      children,
      disabled = false,
      intent = 'primary',
      size = 'md',
      variant = 'filled',
      loading = false,
      className: customClassName,
      fullWidth,
      ...rest
    },
    ref
  ) => {
    const buttonSizeClassName = MAP_SIZE_TO_CLASS[size];
    const className = classNames(buttonSizeClassName, customClassName);

    return (
      <BaseButton
        {...rest}
        width={fullWidth ? '100%' : undefined}
        variant={variant}
        intent={intent}
        loading={loading}
        disabled={disabled}
        ref={ref}
        className={className}
      >
        {children}
      </BaseButton>
    );
  }
);

Button.displayName = 'Button';

export default Button;
