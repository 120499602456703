import React, { useState } from 'react';
import classnames from 'classnames';
import { object, string, bool } from 'prop-types';
import { triggerSignUp } from '@fiverr-private/identification_triggers';
import { Button } from '@fiverr-private/fit';
import pathfinder from '@fiverr-private/pathfinder';
import withTranslation from '../../lib/withTranslation';
import { sendBigQueryEvent } from '../../utils/bigQuery';
import { BIGQUERY_EVENT } from './constants';
import './style.scss';

const JoinLink = ({
    fill = Button.FILLS.GHOST,
    color = Button.TEXT_COLORS.GREEN,
    size = Button.SIZES.SMALL,
    textColor,
    i18n,
    isThemed = false,
}) => {
    const [loading, setLoading] = useState(false);

    const triggerClassName = classnames('fiverr-header-join', { 'themed-link': isThemed });

    const path = pathfinder(
        'join',
        {},
        {
            query: {
                source: 'top_nav',
            },
        }
    );

    const handleClickTriggerApi = async (event) => {
        try {
            event.preventDefault();
            setLoading(true);
            await triggerSignUp({ modalOptions: { source: 'header_join_link' } });
            sendBigQueryEvent(BIGQUERY_EVENT);
        } catch {
            window.location.assign(path);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Button
            href={path}
            loading={loading}
            rel="nofollow"
            color={color}
            textColor={textColor}
            fill={fill}
            size={size}
            className={triggerClassName}
            onClick={handleClickTriggerApi}
        >
            {i18n.t('header.join')}
        </Button>
    );
};

JoinLink.propTypes = {
    fill: string,
    color: string,
    textColor: string,
    size: string,
    i18n: object,
    isThemed: bool,
};

export default withTranslation(JoinLink);
