/**
 * Package wrapper class
 * @type {string}
 */
export const PACKAGE_CLASS = 'locale-settings-package';

/**
 * Package wrapper class for fit
 * @type {string}
 */
export const FIT_PACKAGE_CLASS = 'locale_settings-package';

/**
 * Default prefix for logging
 * @type {string}
 */
export const context = 'LocaleSettingsPackage';

/**
 * Default currency to be selected
 * @type {String}
 */
export const DEFAULT_CURRENCY_LABEL = 'USD';

/**
 * Default currency object in case supportedCurrency array is empty
 * @type {{currencyName: string, currencySymbol: string, currencyLabel: string}}
 */
export const DEFAULT_CURRENCY = {
    currencyLabel: 'USD',
    currencySymbol: '$',
    currencyName: 'United States Dollar',
};

/**
 * Languages to language code map
 * @type {Object}
 */
export enum languagesCodesMap {
    english = 'en-US',
    german = 'de-DE',
    french = 'fr-FR',
    spanish = 'es',
    portuguese = 'pt-BR',
    italian = 'it-IT',
    dutch = 'nl-NL',
}

/**
 * Default language to be selected
 * @type {String}
 */
export const DEFAULT_LANGUAGE = languagesCodesMap.english;

export interface languageConfig {
    locale: string;
    label: string;
    subdomain: string;
    localeMessageTitle: string;
    localeMessageSubTitle: string;
    localeMessageLinkText: string;
}

type LanguageConfigMap = {
    [key in languagesCodesMap]: languageConfig;
};
/**
 * Language config map used to render options under the language selector
 *
 * @type {Object}
 */
export const LANGUAGE_CONFIG_MAP: LanguageConfigMap = {
    [languagesCodesMap.english]: {
        locale: languagesCodesMap.english,
        label: 'English',
        subdomain: 'www',
        localeMessageTitle: 'Welcome to Fiverr!',
        localeMessageSubTitle: 'Want to discover Fiverr in English?',
        localeMessageLinkText: 'Yes, Change to english',
    },
    [languagesCodesMap.german]: {
        locale: languagesCodesMap.german,
        label: 'Deutsch',
        subdomain: 'de',
        localeMessageTitle: 'Willkommen bei Fiverr!',
        localeMessageSubTitle: 'Möchtest du Fiverr auf Deutsch entdecken?',
        localeMessageLinkText: 'Ja, jetzt loslegen',
    },
    [languagesCodesMap.french]: {
        locale: languagesCodesMap.french,
        label: 'Français',
        subdomain: 'fr',
        localeMessageTitle: 'Bienvenue sur Fiverr !',
        localeMessageSubTitle: 'Souhaitez-vous découvrir Fiverr en français ?',
        localeMessageLinkText: "C'est parti !",
    },
    [languagesCodesMap.spanish]: {
        locale: languagesCodesMap.spanish,
        label: 'Español',
        subdomain: 'es',
        localeMessageTitle: '¡Bienvenido a Fiverr!',
        localeMessageSubTitle: '¿Quieres explorar Fiverr en español?',
        localeMessageLinkText: 'Si, cambiar a español',
    },
    [languagesCodesMap.portuguese]: {
        locale: languagesCodesMap.portuguese,
        label: 'Português',
        subdomain: 'br',
        localeMessageTitle: 'Bem-vindo à Fiverr!',
        localeMessageSubTitle: 'Quer conhecer a Fiverr em português?',
        localeMessageLinkText: 'Sim, passe para o português',
    },
    [languagesCodesMap.italian]: {
        locale: languagesCodesMap.italian,
        label: 'Italiano',
        subdomain: 'it',
        localeMessageTitle: 'Benvenuti su Fiverr!',
        localeMessageSubTitle: 'Vuoi scoprire Fiverr in italiano?',
        localeMessageLinkText: "Sì, passa all'italiano",
    },
    [languagesCodesMap.dutch]: {
        locale: languagesCodesMap.dutch,
        label: 'Nederlands',
        subdomain: 'nl',
        localeMessageTitle: 'Welkom bij Fiverr.',
        localeMessageSubTitle: 'Wil je Fiverr ontdekken in het Nederlands?',
        localeMessageLinkText: 'Ja, doorgaan in het Nederlands',
    },
};

/**
 * Supported trigger styles
 * @type {Object}
 */
export const STYLES = {
    DEFAULT: 'default',
    MENU: 'menu',
    LEAN_MENU: 'lean-menu',
};

export const POSITIONS = {
    TOP: 'top',
    BOTTOM: 'bottom',
};

export const LOCALE_SETTINGS_MODAL_TEST_ID = 3927;
export const LIONS_BANNER_LOCAL_SITE_REDIRECT_ID = 4179;

export const ALLOCATIONS = {
    TEST_C: '3',
};

export default STYLES;
