export const SigningModalTypeValues = {
    identification: {
        name: 'IdentificationModal',
    },
    signUp: {
        name: 'SignUpModal',
    },
    signIn: {
        name: 'SignInModal',
    },
} satisfies Record<string, SigningModalType>;

export interface SigningModalType {
    name: 'IdentificationModal' | 'SignUpModal' | 'SignInModal';
}
