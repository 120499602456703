import { logger } from '@fiverr-private/obs';
import { numberFormat } from '../number';

import { localizationContextParams } from '../../../shared/constants';
import { getFiverrLocaleByFormattingLocale } from '../../supportedFormattingLocales';
import { validateOptions } from '../utils/validateOptions';
import { K, FILE_SIZE_FORMATTING_OPTIONS, FILE_SIZE_TEMPLATES } from './constants';

interface fileSizeFormatOptions {
    fallbackValue?: string;
    formattingLocale?: string;
    maximumFractionDigits?: number;
    noFractionDigits?: boolean;
}

interface fileSizeFormatParams {
    localizationContext?: localizationContextParams;
    bytes: number;
    options?: fileSizeFormatOptions;
}

/**
 * Format a file size.
 * Returns the file size as a string, formatted and localized.
 * Based on the implementation from https://stackoverflow.com/question s/15900485/correct-way-to-convert-size-in-bytes-to-kb-mb-gb-in-javascript.
 * @param {Object} localizationContext - the localization context that is being injected
 * dynamically according to the run time env by one of the wrappers - withBrowserContext / withMainContext / withModuleContext
 * @param {number} bytes The file's size in bytes.
 * @param {Object} [options={}] Optional formatting configurations.
 * @returns {string} Formatted value string.
 */
export const fileSizeFormat = ({ localizationContext, bytes, options = {} }: fileSizeFormatParams) => {
    const fallbackValue = options.fallbackValue || FILE_SIZE_TEMPLATES[0](bytes);

    try {
        if (!localizationContext || Object.keys(localizationContext).length === 0) {
            return fallbackValue;
        }

        const formattingLocale = options.formattingLocale || localizationContext!.formattingLocale!;
        const parsedOptions = parseOptions(options);
        const i = Math.max(0, Math.floor(Math.log(bytes) / Math.log(K)));
        const fileSize = numberFormat({
            localizationContext,
            value: bytes / Math.pow(K, i),
            options: {
                formattingLocale,
                maximumFractionDigits: parsedOptions.maximumFractionDigits,
                noFractionDigits: parsedOptions.noFractionDigits,
            },
        });

        const fileSizeKey = `fileSizes.abbrv[${i}]`;
        const locale = getFiverrLocaleByFormattingLocale(formattingLocale);
        const isLocalizedKeyExists = i18n.has(fileSizeKey, { $scope: locale });

        if (typeof i18n === 'undefined' || (!isNaN(i) && !isLocalizedKeyExists)) {
            return FILE_SIZE_TEMPLATES[i](fileSize as string);
        }

        return i18n.t(fileSizeKey, {
            fileSize: fileSize as string,
            $scope: locale,
        });
    } catch (e) {
        logger.error(e as Error, { bytes, options, formatter: 'fileSizeFormat' });
        return fallbackValue;
    }
};

/**
 * Parse formatter options.
 * This method validates the properties in the options object and overrides properties for Fiverr custom formatting rules.
 * @param {Object} options Optional formatting configurations.
 * @returns {Object} Validated formatting configurations.
 */
export const parseOptions = (options) => validateOptions(FILE_SIZE_FORMATTING_OPTIONS, options);
