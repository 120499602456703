import { lazy } from 'react';
import { EventTypes } from '../../../events/types';
import CurrencySelectionTrigger from '../../../../components/CurrencySelectionTrigger';

const currencySelectionTrigger = {
    Trigger: CurrencySelectionTrigger,
    resolveModal: () =>
        lazy(() =>
            import(
                /* webpackChunkName: 'CurrencySelectionModal' */
                '../../../../components/CurrencySelectionModal'
            ).catch((() => null) as any)
        ),
    eventRoot: EventTypes.currency,
};

export default currencySelectionTrigger;
