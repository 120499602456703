import { EventTypes } from '../../types';
import { createGrafanaEvent } from '../index';

const { language, currency, locale } = EventTypes;

export const GrafanaEventTypes = {
    [language.modal.triggered]: createGrafanaEvent(language.modal.triggered),
    [language.modal.opened]: createGrafanaEvent(language.modal.opened),
    [language.modal.closed]: createGrafanaEvent(language.modal.closed),
    [language.update.success]: createGrafanaEvent(language.update.success),
    [language.update.fail]: createGrafanaEvent(language.update.fail),
    [language.localeMessage.show]: createGrafanaEvent(language.localeMessage.show),
    [language.localeMessage.closed]: createGrafanaEvent(language.localeMessage.closed),
    [language.localeMessage.clicked]: createGrafanaEvent(language.localeMessage.clicked),
    [currency.modal.triggered]: createGrafanaEvent(currency.modal.triggered),
    [currency.modal.opened]: createGrafanaEvent(currency.modal.opened),
    [currency.modal.closed]: createGrafanaEvent(currency.modal.closed),
    [currency.update.success]: createGrafanaEvent(currency.update.success),
    [currency.update.fail]: createGrafanaEvent(currency.update.fail),
    [locale.modal.triggered]: createGrafanaEvent(locale.modal.triggered),
    [locale.modal.opened]: createGrafanaEvent(locale.modal.opened),
    [locale.modal.closed]: createGrafanaEvent(locale.modal.closed),
    [locale.tab.currency]: createGrafanaEvent(locale.tab.currency),
    [locale.tab.language]: createGrafanaEvent(locale.tab.language),
};
