// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// fix modal import approach to support typescript
import React, { Suspense, useState, useContext, ReactElement } from 'react';
import classNames from 'classnames';
import { isFunction } from 'lodash';
import Context from '../../../contexts/app';
import { DEFAULT_CURRENCY_LABEL, DEFAULT_LANGUAGE, PACKAGE_CLASS, FIT_PACKAGE_CLASS } from '../../../lib/constants';
import { localeSettings } from '../../../lib/types';
import { reportEvents } from '../../../utils/events';

const withEntry = ({ Trigger, resolveModal, eventRoot }) => {
    const Entry = ({
        locale = DEFAULT_LANGUAGE,
        currency = DEFAULT_CURRENCY_LABEL,
        supportedCurrencies = [],
        source,
        user,
        className,
        ...props
    }: localeSettings) => {
        const [Modal, setModal] = useState<ReactElement | null>(null);

        const { language } = useContext(Context);
        const selectedCurrency = supportedCurrencies.find(({ currencyLabel }) => currencyLabel === currency);
        const biEnrichmentParams = { source, user, language, currency };

        let openModal, closeModal;

        const setModalTrigger = ({ open, close }) => {
            openModal = open;
            closeModal = close;
        };

        const onTrigger = (event) => {
            event.preventDefault();

            reportEvents(eventRoot.modal.triggered, biEnrichmentParams);

            if (isFunction(openModal)) {
                openModal();
            } else {
                setModal(resolveModal());
            }
        };

        const onUpdate = () => {
            if (isFunction(closeModal)) {
                closeModal();
            }
        };

        const wrapperClass = classNames(PACKAGE_CLASS, className, FIT_PACKAGE_CLASS);

        const enhancedProps = {
            ...props,
            locale,
            source,
            user,
            language,
            currency: selectedCurrency,
            supportedCurrencies,
            biEnrichmentParams,
        };

        return (
            <section className={wrapperClass}>
                <Trigger {...enhancedProps} onTrigger={onTrigger} />
                {Modal && (
                    <Suspense fallback={null}>
                        <Modal trigger={setModalTrigger} onUpdate={onUpdate} {...enhancedProps} />
                    </Suspense>
                )}
            </section>
        );
    };

    return Entry;
};

export default withEntry;
