import type { ElementType, AriaRole } from 'react';

const useGetButtonElement = (
    href
): {
    element: ElementType;
    role: AriaRole;
} => {
    if (href) {
        return {
            element: 'a',
            role: 'link',
        };
    } else {
        return {
            element: 'button',
            role: 'button',
        };
    }
};

export default useGetButtonElement;
