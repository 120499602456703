export const SYSTEM_ICON_SIZES = {
    xs: 12,
    sm: 16,
    md: 20,
} as const;

export const L1_ICON_SIZES = {
    sm: 24,
    md: 32,
} as const;
