import { isFunction } from 'lodash';
import { sendBigQueryEvent } from './bigQuery';
import { sendGraphCount } from './grafana';
import { BigQueryEventTypes } from './bigQuery/types';
import { GrafanaEventTypes } from './grafana/types';
import { MixpanelEventTypes } from './mixpanel/types';
import { sendMixpanel } from './mixpanel';

/**
 * Reports event to all reporting channels (Grafana, BigQuery, Mixpanel).
 *
 * @param eventKey
 * @param params
 */
export const reportEvents = (eventKey, params = {}) => {
    if (!eventKey) {
        return;
    }

    reportEvent(BigQueryEventTypes[eventKey], sendBigQueryEvent, params);
    reportEvent(GrafanaEventTypes[eventKey], sendGraphCount, params);
    reportEvent(MixpanelEventTypes[eventKey], sendMixpanel, params);
};

const reportEvent = (event, reporter, params) => {
    if (!event) {
        return;
    }

    const enrichedEvent = isFunction(event) ? event(params) : event;

    if (enrichedEvent) {
        reporter(enrichedEvent);
    }
};
