import React from 'react';
import { string } from 'prop-types';
import pathfinder from '@fiverr-private/pathfinder';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import Link from '../Link';
import { TOP_NAV_SOURCE } from '../../../constants';

export const BecomeSellerJobPageLink = ({ source, ...props }) => {
    const { locale } = getContext();
    const localizedPath =
        {
            [SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE]: 'freelance-jobs',
            [SUPPORTED_FIVERR_LOCALES_KEYS.ES]: 'freelance-jobs',
            [SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR]: 'freelance-jobs',
        }[locale] || 'freelance-jobs';

    const path = pathfinder(
        'seller_job_page',
        {
            hub_id: localizedPath,
        },
        {
            query: {
                source,
            },
        }
    ).replace(/\)/g, ''); // (https://github.com/fiverr/pathfinder/issues/45)

    return (
        <Link {...props} href={path} className="nav-link" rel="nofollow">
            {(i18n) => i18n.t('header.become_seller')}
        </Link>
    );
};

BecomeSellerJobPageLink.propTypes = {
    source: string,
};

BecomeSellerJobPageLink.defaultProps = {
    source: TOP_NAV_SOURCE,
};
