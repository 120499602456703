import React from 'react';
import { bool, func } from 'prop-types';
import classnames from 'classnames';
import { Dropdown, Menu } from '@fiverr-private/orca';
import { I18n } from '@fiverr-private/i18n-react';
import { user, abTests, rollouts } from '../../PropTypes';
import Link from '../Links/Link';
import { DropdownTriggerButton } from '../DropdownTriggerButton';
import { getBQData } from '../../utils/business/bi-utils';
import { OptionItem } from './OptionItem';
import { getProUrl, getProFreelancerUrl } from './common';

import styles from './FiverrProDropdown.module.scss';

const T_PREFIX = 'header.fiverr_pro_entry';

export const FiverrProDropdown = ({ isTransparent, onOpen }, { user, rollouts }) => {
    const HREF = {
        pro: getProUrl(),
        freelancer: getProFreelancerUrl(),
    };

    const linkProps = {
        className: styles.optionLink,
        target: '_blank',
    };

    return (
        <Dropdown
            containerClassName={styles.menuContainer}
            boxClassName={styles.boxMenu}
            contentClassName={styles.contentMenu}
            onOpen={onOpen}
            position={Dropdown.POSITIONS.LEFT}
            menu={
                <Menu className={classnames(styles.menu, 'header-package business-entry solutions-menu')}>
                    <Menu.Item className={styles.menuItem}>
                        <Link {...linkProps} href={HREF.pro} bigQueryData={getBQData({ type: 'hire_freelancer' })}>
                            <OptionItem
                                icon={<span className={styles.hireIcon} />}
                                title={<I18n k={`${T_PREFIX}.menu.hire_freelancer.title`} />}
                                subTitle={<I18n k={`${T_PREFIX}.menu.hire_freelancer.sub_title`} />}
                            />
                        </Link>
                    </Menu.Item>
                    <Menu.Item className={styles.menuItem}>
                        <Link
                            {...linkProps}
                            href={HREF.freelancer}
                            bigQueryData={getBQData({ type: 'i_am_freelancer' })}
                        >
                            <OptionItem
                                icon={<span className={styles.iamIcon} />}
                                title={<I18n k={`${T_PREFIX}.menu.iam_freelancers.title`} />}
                                subTitle={<I18n k={`${T_PREFIX}.menu.iam_freelancers.sub_title`} />}
                            />
                        </Link>
                    </Menu.Item>
                </Menu>
            }
            user={user}
            rollouts={rollouts}
            appendToBody={false}
        >
            {({ isOpen }) => (
                <DropdownTriggerButton
                    title={<I18n k={`${T_PREFIX}.title`} />}
                    bigQueryData={getBQData({ type: 'open_menu_fiverr_pro', component: { name: 'Header' } })}
                    isOpen={isOpen}
                    isTransparent={isTransparent}
                />
            )}
        </Dropdown>
    );
};

FiverrProDropdown.propTypes = {
    isTransparent: bool,
    onOpen: func,
};

FiverrProDropdown.contextTypes = {
    abTests,
    user,
    rollouts,
};
