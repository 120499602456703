import { logger } from '@fiverr-private/obs';
import { ExternalTriggerParams } from '../types';
import { isBrowser } from '../../../utils/isBrowser';
import { waitForWindowMethod } from './utils/waitForWindowMethod';
import { WindowModalMethodName } from './types';

const createBusinessTrigger =
    (methodName: WindowModalMethodName) =>
    async (params: ExternalTriggerParams = {}) => {
        const { modalOptions = {}, biEnrichmentParams = {} } = params;

        if (!isBrowser()) {
            return;
        }

        try {
            await waitForWindowMethod(methodName);
            const method = window[methodName] || (() => null);

            return method(modalOptions, biEnrichmentParams);
        } catch (e) {
            logger.warn(`Identification Trigger: Failed to resolve a trigger for business ${methodName}`);
        }
    };

export const businessOpenSignUp = createBusinessTrigger('openSignUpModal');

export const businessOpenSignIn = createBusinessTrigger('openSignInModal');
