import { BIGQUERY_GROUP } from '../../constants';

/**
 * Type name of the BigQuery event.
 * @type {String}
 */
export const BIGQUERY_TYPE = 'clicked_header.registration.button_click_header';

/**
 * BigQuery event to send on click.
 * @type {Object}
 */
export const BIGQUERY_EVENT = {
    type: BIGQUERY_TYPE,
    group: BIGQUERY_GROUP,
};
