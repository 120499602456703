import React, { forwardRef } from 'react';
import type { Types } from '@fiverr-private/theme';
import Layout from '../Layout';

const Container = forwardRef<HTMLElement, Types.ContainerProps>(
  ({ children, ...restProps }: Types.ContainerProps, ref) => (
    <Layout ref={ref} {...restProps}>
      {children}
    </Layout>
  )
);

Container.displayName = 'Container';

export default Container;
