import React from 'react';
import classNames from 'classnames';
import { bool } from 'prop-types';
import { triggerSignIn } from '@fiverr-private/identification_triggers';
import { I18n } from '@fiverr-private/i18n-react';
import pathfinder from '@fiverr-private/pathfinder';
import { sendBigQueryEvent } from '../../utils/bigQuery';
import { sendMixPanelEvent } from '../../utils/mixPanel';
import withTranslation from '../../lib/withTranslation';
import { BIGQUERY_EVENT, MIXPANEL_EVENT } from './constants';

const LoginLink = ({ isThemed = false }) => {
    const triggerClassName = classNames({
        'nav-link': true,
        'themed-link': isThemed,
    });

    const path = pathfinder(
        'login',
        {},
        {
            query: {
                source: 'top_nav',
            },
        }
    );

    const handleClickTriggerApi = async (event) => {
        try {
            event.preventDefault();
            await triggerSignIn({ modalOptions: { source: 'header_login_link' } });
            sendBigQueryEvent(BIGQUERY_EVENT);
            sendMixPanelEvent(MIXPANEL_EVENT);
        } catch {
            window.location.assign(path);
        }
    };

    return (
        <a rel="nofollow" href={path} className={triggerClassName} onClick={handleClickTriggerApi}>
            <I18n k="header.sign_in" />
        </a>
    );
};

LoginLink.propTypes = {
    isThemed: bool,
};

export default withTranslation(LoginLink);
