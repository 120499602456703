import React from 'react';
import { number, string, bool } from 'prop-types';
import classnames from 'classnames';

import './style.scss';

const VerticalDivider = ({ height = 24, className, displayFromSM, displayFromMD, displayFromLG, displayFromXL }) => (
    <li
        className={classnames('vertical-divider', className, {
            'display-from-sm': displayFromSM,
            'display-from-md': displayFromMD,
            'display-from-lg': displayFromLG,
            'display-from-xl': displayFromXL,
        })}
        style={{ height: `${height}px` }}
    />
);

VerticalDivider.propTypes = {
    height: number,
    className: string,
    displayFromSM: bool,
    displayFromMD: bool,
    displayFromLG: bool,
    displayFromXL: bool,
};

export default VerticalDivider;
