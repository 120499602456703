import { getShortLanguageCode } from '../shortLanguageCode';
import {
    SUPPORTED_FIVERR_LOCALES_MAP,
    DEFAULT_FIVERR_LOCALE as DEFAULT_FIVERR_LOCALE_CONST,
    LOCALE_TO_SUBDOMAIN_MAP,
    SUPPORTED_FIVERR_LANGUAGES as SUPPORTED_FIVERR_LANGUAGES_CONST,
} from './constants';

export const DEFAULT_FIVERR_LOCALE = DEFAULT_FIVERR_LOCALE_CONST;
export const SUPPORTED_FIVERR_LOCALES = Object.keys(SUPPORTED_FIVERR_LOCALES_MAP);
export const DEFAULT_FIVERR_LANGUAGE = getShortLanguageCode(DEFAULT_FIVERR_LOCALE);
export const SUPPORTED_FIVERR_LANGUAGES = SUPPORTED_FIVERR_LANGUAGES_CONST;
export { LOCALE_TO_SUBDOMAIN_MAP };

/**
 * Build a map of the supported Fiverr locales in the form of:
 * {
 *     EN_US: 'en-US',
 *     DE_DE: 'de-DE',
 *     ES: 'es,
 *     ...
 * }
 * So consumers can check for specific locale in their code.
 * @returns {{localeKey: String}} - the native name of the locale
 */
const localesKeys = {};
Object.keys(SUPPORTED_FIVERR_LOCALES_MAP).forEach((locale) => {
    const localeKey = locale.toUpperCase().replace(/-/g, '_');
    localesKeys[localeKey] = locale;
});
export const SUPPORTED_FIVERR_LOCALES_KEYS = localesKeys;

/**
 * Check if Fiverr supports this locale.
 * Meaning: we have a Fiverr experience in this locale (UI language).
 * @param locale {string} - check for existence as-is, no trimming, etc.
 * @returns {boolean} - the locale is supported or not
 */
export const isSupportedFiverrLocale = (locale) => !!SUPPORTED_FIVERR_LOCALES_MAP[locale];

/**
 * Returns the native name of the locale.
 * @param locale {string} - check for existence as-is, no trimming, etc.
 * @returns {string} - the native name of the locale
 */
export const getFiverrLocaleNativeName = (locale) => SUPPORTED_FIVERR_LOCALES_MAP[locale] || locale;

const MAIN_SUBDOMAIN = LOCALE_TO_SUBDOMAIN_MAP[DEFAULT_FIVERR_LOCALE];
const AQUARIUM_PREFIX = 'aq-';
const subdomainMatchGroup = Object.values(LOCALE_TO_SUBDOMAIN_MAP).join('|');
const IS_FIVERR_COM_REGEX = new RegExp(`^((${subdomainMatchGroup})\\.){1,2}(dev\\.){0,1}fiverr.com$`);

/**
 * Add target subdomain to hostname
 *
 * @param {string} hostname
 * @param {string} targetSubdomain
 * @returns {string} local site hostname
 */
export const replaceLocaleSubdomain = (hostname, targetSubdomain) => {
    const subdomains = hostname.split('.');
    const currentSubdomain = Object.values(LOCALE_TO_SUBDOMAIN_MAP).find((subdomain) => subdomains.includes(subdomain));
    const currentSubdomainIndex = subdomains.indexOf(currentSubdomain);

    if (isSameSubdomain(currentSubdomain, targetSubdomain)) {
        return hostname;
    }

    // target locale is English and we're on a subsystem of Fiverr (e.g Fiverr Business, Aquarium etc)
    if (targetSubdomain === MAIN_SUBDOMAIN && !IS_FIVERR_COM_REGEX.test(hostname)) {
        // only remove the current subdomain
        subdomains.splice(currentSubdomainIndex, 1);
    } else if (subdomains[0].startsWith(AQUARIUM_PREFIX) && currentSubdomainIndex === -1) {
        // Aquarium (default locale)
        // inserting target subdomain after Aquarium id
        subdomains.splice(1, 0, targetSubdomain);
    } else if (currentSubdomainIndex !== -1) {
        // replacing existing subdomain
        subdomains.splice(currentSubdomainIndex, 1, targetSubdomain);
    } else {
        // might happen on Fiverr Business (default locale)
        // appending target subdomain at the beginning of the hostname
        subdomains.splice(0, 0, targetSubdomain);
    }

    return subdomains.join('.');
};

/**
 * Checks if `current` and `target` subdomains point to the same local site.
 * Usually, we want both of them to be equal by value, but on Aquarium or Fiverr Business
 * or other Fiverr subsystems, there's on `www` when browsing the English site, so in that
 * case, `taret` will be `www` but `current` will be empty.
 *
 * @param {string} current current subdomain
 * @param {string} target target subdomain
 * @returns {boolean} whether both subdomains point to the same local site.
 */
const isSameSubdomain = (current, target) => current === target || (target === MAIN_SUBDOMAIN && !current);

/**
 * Convert locale to its matching subdomain (e.g "en-US" -> "www", "pt-BR" -> "br").
 * Fallback is the main subdomain which is "www".
 * @param {string} locale
 * @returns the matching subdomain
 */
export const getLocaleSubdomain = (locale) => LOCALE_TO_SUBDOMAIN_MAP[locale] || MAIN_SUBDOMAIN;
