import React from 'react';
import { getContext } from '@fiverr-private/fiverr_context';
import { SUPPORTED_FIVERR_LOCALES_KEYS } from '@fiverr-private/localization';
import { BecomeSellerLink } from '../../Links/BecomeSellerLink';
import { BecomeSellerJobPageLink } from '../../Links/BecomeSellerJobPageLink';
import { bigQueryGuest } from '../bigQueryGuest';

export const GuestBecomeSellerLink = () => {
    const { locale } = getContext();
    const shouldRouteToSellerJobPages = [
        SUPPORTED_FIVERR_LOCALES_KEYS.DE_DE,
        SUPPORTED_FIVERR_LOCALES_KEYS.ES,
        SUPPORTED_FIVERR_LOCALES_KEYS.FR_FR,
    ].includes(locale);

    return shouldRouteToSellerJobPages ? (
        <BecomeSellerJobPageLink bigQueryData={bigQueryGuest('start_selling')} />
    ) : (
        <BecomeSellerLink bigQueryData={bigQueryGuest('start_selling')} />
    );
};
