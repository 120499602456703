import { lazy } from 'react';
import { EventTypes } from '../../../events/types';
import LanguageSelectionTrigger from '../../../../components/LanguageSelectionTrigger';

const languageSelectionTrigger = {
    Trigger: LanguageSelectionTrigger,
    resolveModal: () =>
        lazy(() =>
            import(
                /* webpackChunkName: 'LanguageSelectionModal' */
                '../../../../components/LanguageSelectionModal'
            ).catch(() => null as any)
        ),
    eventRoot: EventTypes.language,
};

export default languageSelectionTrigger;
