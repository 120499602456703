import React from 'react';
import { bool } from 'prop-types';
import classnames from 'classnames';
import { STYLES } from '@fiverr-private/locale_settings';
import LocaleSettingsLink from '../LocaleSettingsLink';

import './style.scss';

const LeanLocaleSettingsLink = ({ hideLabel = false } = {}) => (
    <div className={classnames('lean-locale-settings', { 'hide-label': hideLabel })}>
        <LocaleSettingsLink style={STYLES.LEAN_MENU} />
    </div>
);

LeanLocaleSettingsLink.propTypes = {
    hideLabel: bool,
};

export default LeanLocaleSettingsLink;
