import { getShortLanguageCode } from '../shortLanguageCode';
import { SUPPORTED_FIVERR_LOCALES } from '../supportedFiverrLocales';
import {
    SUPPORTED_FORMATTING_LOCALES_MAP,
    DEFAULT_FORMATTING_LOCALE as DEFAULT_FORMATTING_LOCALE_CONST,
    FORMATTING_LOCALE_FALLBACK_MAP,
} from './constants';

export const DEFAULT_FORMATTING_LOCALE = DEFAULT_FORMATTING_LOCALE_CONST;
export const SUPPORTED_FORMATTING_LOCALES = Object.keys(SUPPORTED_FORMATTING_LOCALES_MAP);
export const DEFAULT_FORMATTING_LANGUAGE = getShortLanguageCode(DEFAULT_FORMATTING_LOCALE);

/**
 * Build a map of the supported Formatting locales in the form of:
 * {
 *     EN_US: 'en-US',
 *     DE_DE: 'de-DE',
 *     ES: 'es,
 *     ...
 * }
 * So consumers can check for specific formatting locale in their code.
 * @returns {{localeKey: String}} - the native name of the formatting locale
 */
const localesKeys = {};
Object.keys(SUPPORTED_FORMATTING_LOCALES_MAP).forEach((locale) => {
    const localeKey = locale.toUpperCase().replace(/-/g, '_');
    localesKeys[localeKey] = locale;
});
export const SUPPORTED_FORMATTING_LOCALES_KEYS = localesKeys;

/**
 * Calculate the formatting locale
 *
 * Current logic (prioritized):
 * - If browserLanguage = fiverrLanguage (fiverrLocale prefix) AND browserLocale is supported formatting locale -
 *      return browserLocale as formattingLocale.
 * - If fiverrLanguage + countryCode is supported formatting locale -
 *      return fiverrLanguage + countryCode as formatting locale.
 * - If fiverrLanguage = 'en' -
 *      return DEFAULT_FORMATTING_LOCALE as formatting locale.
 * - If fiverrLanguage + countryCode exists in FORMATTING_LOCALE_FALLBACK_MAP -
 *      return formatting locale from mapping.
 * - If fiverrLocale exists in FORMATTING_LOCALE_FALLBACK_MAP -
 *      return formatting locale from mapping.
 * - If none of the above -
 *      return fiverrLocale as formattingLocale, if not supplied - return the default formatting locale
 *
 * @param {FormattingLocaleParams} {{ locale: String, browserLanguage: String, countryCode: String }} -
 *      The locale, country code and the browser language
 * @returns {string} - the calculated formatting locale (e.g 'en-GB' / 'es-MX')
 */
export const calculateFormattingLocale = ({ locale, browserLanguage, countryCode }) => {
    // Fix Safari browser issue
    // @see https://bugs.webkit.org/show_bug.cgi?id=163096
    browserLanguage = normalizeBrowserLanguage(browserLanguage);
    const browserLanguageShort = browserLanguage && getShortLanguageCode(browserLanguage);
    const fiverrLanguageShort = locale && getShortLanguageCode(locale);
    if (browserLanguageShort === fiverrLanguageShort && isSupportedFormattingLocale(browserLanguage)) {
        return browserLanguage;
    }
    const fiverrLangWithCountryCode = `${fiverrLanguageShort}-${countryCode}`;
    if (isSupportedFormattingLocale(fiverrLangWithCountryCode)) {
        return fiverrLangWithCountryCode;
    }
    if (fiverrLanguageShort === DEFAULT_FORMATTING_LANGUAGE) {
        return DEFAULT_FORMATTING_LOCALE;
    }
    return (
        FORMATTING_LOCALE_FALLBACK_MAP[fiverrLangWithCountryCode] ||
        FORMATTING_LOCALE_FALLBACK_MAP[locale] ||
        locale ||
        DEFAULT_FORMATTING_LOCALE
    );
};

/**
 * Normalize the browser language string (i.e uppercase the country code)
 * @param browserLanguage {string} - the browser language of the user
 * @returns {string} - the normalized form of the browser language
 */
export const normalizeBrowserLanguage = (browserLanguage?: string) =>
    browserLanguage && browserLanguage.replace(/-(.{2})/, (country) => country.toUpperCase());

/**
 * Check if a locale is a valid formatting locale.
 * @param locale {string} - check for existence as-is, no trimming, etc.
 * @returns {boolean} - the locale is supported or not
 */
export const isSupportedFormattingLocale = (locale?: string) => !!locale && !!SUPPORTED_FORMATTING_LOCALES_MAP[locale];

/**
 * Returns the native name of the formatting locale.
 * @param locale {string} - check for existence as-is, no trimming, etc.
 * @returns {string} - the native name of the formatting locale
 */
export const getFormattingLocaleNativeName = (locale: string) => SUPPORTED_FORMATTING_LOCALES_MAP[locale] || locale;

/**
 * Get a formatting locales corresponding Fiverr locale.
 * @param formattingLocale {string} - check for existence as-is, no trimming, etc.
 * @returns {string} - the corresponding Fiverr locale.
 */
export const getFiverrLocaleByFormattingLocale = (formattingLocale: string) =>
    SUPPORTED_FIVERR_LOCALES.find(
        (fiverrLocale) => getShortLanguageCode(fiverrLocale) === getShortLanguageCode(formattingLocale)
    );

/**
 * Get a Fiverr locale with corresponding language code.
 * @param languageCode {string} - language code. Examples: de, fr, it
 * @returns {string|undefined} - the corresponding Fiverr locale.
 */
export const getFiverrLocaleByLanguageCode = (languageCode: string) =>
    SUPPORTED_FIVERR_LOCALES.find((fiverrLocale) => getShortLanguageCode(fiverrLocale) === languageCode);
