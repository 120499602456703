import { lazy } from 'react';
import { EventTypes } from '../../../events/types';
import LanguageSelectionTrigger from '../../../../components/LanguageSelectionTrigger';

const localeSelectionTrigger = {
    Trigger: LanguageSelectionTrigger,
    resolveModal: () =>
        lazy(() =>
            import(/* webpackChunkName: 'LocaleSelectionModal' */ '../../../../components/LocaleSelectionModal').catch(
                () => null as any
            )
        ),
    eventRoot: EventTypes.locale,
};

export default localeSelectionTrigger;
