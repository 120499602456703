import React from 'react';
import { user } from '../lib/types';

export interface ContextProviderProps {
    language: string;
    titleClassName: string;
    itemClassName: string;
    source: string;
    user: user;
    currency: string;
}

const Context = React.createContext<ContextProviderProps>({} as ContextProviderProps);

export default Context;
