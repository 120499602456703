import { merge } from 'lodash';
import { getContext } from '@fiverr-private/fiverr_context';
import { mixpanel } from '@fiverr-private/obs';
import { sendBigQueryEvent } from '../bigQuery';
import { BASE_BIG_QUERY_PARAMS } from './constants';

export const reportBigQueryClick = (bigQueryData) => {
    if (!bigQueryData) {
        return;
    }

    const { pageCtxId } = getContext();

    const page = {
        ctx_id: pageCtxId,
    };

    const eventPayload = merge({ page }, BASE_BIG_QUERY_PARAMS, bigQueryData);

    sendBigQueryEvent(eventPayload);
};

export const reportMixpanelClick = (mpData) => {
    if (!mpData) {
        return;
    }

    mixpanel.track(mpData.eventName, mpData.eventData);
};
