import _ from 'lodash';
import { mixpanel } from '@fiverr-private/obs';
import { biEnrichmentParams } from '../../../lib/types';

/**
 * Send mixpanel event using `MixpanelWrapper` utility with fallback to obs
 *
 * @param eventName
 * @param params
 */
export const sendMixpanel = ({ type, ...params }) => {
    if (typeof global.MixpanelWrapper === 'object' && typeof global.MixpanelWrapper.trackEvent === 'function') {
        global.MixpanelWrapper.trackEvent(type, params);
    } else {
        mixpanel.track(type, params);
    }
};

export const createMixpanelEvent =
    (type) =>
    (params = {}) => {
        const baseParams = buildBaseParams(type);
        const enrichedParams = buildEnrichedParams(params);

        return _.merge({}, baseParams, enrichedParams);
    };

const buildBaseParams = (type) => ({ type });

const buildEnrichedParams = ({ user, region, source, currency, language }: biEnrichmentParams) => ({
    Currency: _.get(user, 'display_currency', currency),
    'Previous Currency': _.get(region, 'currency.previous_value'),
    Language: _.get(user, 'locale', language),
    'Previous Language': _.get(region, 'language.previous_value'),
    Source: source,
});
