import React from 'react';
import PropTypes from 'prop-types';

import './style.scss';

const PopoverItem = ({ children, className }) => <li className={className}>{children}</li>;

PopoverItem.propTypes = {
    children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
    className: PropTypes.string,
};

PopoverItem.defaultProps = {
    children: null,
};

export default PopoverItem;
