import { getCurrencySymbol } from '@fiverr-private/localization';
import { currency } from '../../lib/types';

export const formatCurrency = ({ currencyName, currencyLabel }: { currencyName: string; currencyLabel: string }) => {
    const currencySymbol = getCurrencySymbol({ currencyCode: currencyLabel });
    return `${currencyName} (${currencyLabel}) - ${currencySymbol}`;
};

export const shortCurrencyFormatter = ({ currencyLabel }: { currencyLabel: string }) => {
    const currencySymbol = getCurrencySymbol({ currencyCode: currencyLabel });
    return `${currencyLabel} - ${currencySymbol}`;
};

export const currencyLabelFormatter = ({ currencyLabel }: { currencyLabel: string }) => {
    const currencySymbol = getCurrencySymbol({ currencyCode: currencyLabel });
    return currencySymbol === currencyLabel ? currencyLabel : `${currencySymbol} ${currencyLabel}`;
};

export const findCurrencyByLabel = (currencies: currency[], currencyLabel: string) =>
    currencies.find((currency) => currency.currencyLabel === currencyLabel);
