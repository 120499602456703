import React from 'react';
import classnames from 'classnames';
import { string, bool } from 'prop-types';
import { sendBigQueryEvent } from '../../utils/bigQuery';
import { fill, user, showNaviconButton } from '../../PropTypes';
import { BODY_SIDEBAR_SHOWN_CLASS } from '../../constants';
import isTouchDevice from '../../lib/isTouchDevice';
import NavIcon from './navicon';
import './style.scss';

/**
 * "Opens" the sidebar by adding the appropriate class to the <body> element.
 */
const openSidebar = () => {
    document.body.classList.add(BODY_SIDEBAR_SHOWN_CLASS);
};

/**
 * Reports the navicon click to BigQuery.
 * @param {Object} [user] Current user data.
 */
const report = (user) => {
    const data = {
        type: 'clicked_on_side_bar_button',
        platform: isTouchDevice() ? 'mobile_web' : 'web',
        group: 'user_actions',
        sub_group: 'website_actions',
        action: {
            type: 'click',
        },
        feature: {
            name: 'menu_side_bar',
        },
        page: {
            element_name: 'menu_side_bar',
        },
        publisher: {
            domain: 'platform',
            team: 'ants',
        },
    };

    if (user) {
        Object.assign(data, {
            user: {
                id: user.id,
            },
        });
    }

    sendBigQueryEvent(data);
};

/**
 * Handles the click event, executing relevant functions.
 * @param {Object} [user] Current user data.
 */
const handleClick = (user) => {
    report(user);
    openSidebar();
};

const Navicon = ({ fill, className, displayFromSM, displayFromMD, displayFromLG }, { user, showNaviconButton }) => {
    if (!showNaviconButton) {
        return null;
    }

    const classNames = classnames(
        'btn-navicon js-side-nav-trigger',
        {
            'display-from-sm': displayFromSM,
            'display-from-md': displayFromMD,
            'display-from-lg': displayFromLG,
        },
        className
    );

    return (
        <button className={classNames} onClick={() => handleClick(user)}>
            <NavIcon fill={fill} />
        </button>
    );
};

Navicon.contextTypes = {
    user,
    showNaviconButton,
};

Navicon.propTypes = {
    fill,
    className: string,
    displayFromSM: bool,
    displayFromMD: bool,
    displayFromLG: bool,
};

export default Navicon;
