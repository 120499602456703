/**
 * @param {Object[]} currencies
 *
 * @returns {{currencyName, currencySymbol, currencyLabel}[]}
 *
 * transformed currencies composed of camelCase property format
 */
export const transformCurrencies = (currencies = []) =>
    currencies.map(({ currency, currency_symbol, currency_name }) => ({
        currencyLabel: currency,
        currencySymbol: currency_symbol,
        currencyName: currency_name,
    }));
