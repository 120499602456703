import React, { forwardRef } from 'react';
import { SpinnerIcon } from '@fiverr-private/visuals';
import { Box } from '@fiverr-private/theme';
import useGetButtonElement from './hooks/useGetButtonElement';
import type { BaseButtonProps } from './types';
import { getStylingProps } from './style';

const BaseButton = forwardRef<HTMLButtonElement | HTMLAnchorElement, BaseButtonProps>(
  (
    {
      children,
      href,
      loading = false,
      disabled = false,
      className,
      intent = 'primary',
      shape = 'square',
      variant = 'filled',
      colorScheme,
      borderRadius,
      backgroundColor,
      color,
      outlineColor,
      ...rest
    },
    ref
  ) => {
    const { element, role } = useGetButtonElement(href);
    const stylingProps = getStylingProps(
      variant,
      loading,
      disabled,
      colorScheme || (intent === 'destructive' ? 'red' : undefined),
      shape,
      (intent === 'primary' || intent === 'secondary') && !colorScheme ? intent : undefined,
      outlineColor
    );
    const currentColor = color || stylingProps.color;

    return (
      <Box
        {...stylingProps}
        {...rest}
        backgroundColor={backgroundColor || stylingProps.backgroundColor}
        borderRadius={borderRadius || stylingProps.borderRadius}
        color={loading ? 'transparent' : currentColor}
        as={element}
        role={role}
        ref={ref}
        href={href}
        disabled={disabled}
        className={className}
      >
        {children}
        {loading && (
          <Box
            as="span"
            visibility={loading ? 'visible' : 'hidden'}
            position="absolute"
            display="inlineFlex"
            color={stylingProps.color}
          >
            <SpinnerIcon />
          </Box>
        )}
      </Box>
    );
  }
);

BaseButton.displayName = 'BaseButton';

export default BaseButton;
