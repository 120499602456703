import { localStorage } from '@fiverr-private/futile';
import { setCookie, getCookie } from '../../../utils/helpers';
import {
    HIDE_LOCALE_MESSAGE_COOKIE,
    MESSAGE_VIEW_COUNT_KEY,
    FIVERR_BASE_DOMAIN_NAME,
    COOKIE_EXPIRATION,
    CONTROL_VARIANT,
} from '../constants';

export const setLocaleMessageViewCounter = () => {
    const currentViewCount = localStorage.get(MESSAGE_VIEW_COUNT_KEY) || 0;
    const updatedViewCount = (parseInt(currentViewCount, 10) || 0) + 1;
    localStorage.set(MESSAGE_VIEW_COUNT_KEY, updatedViewCount);
};

export const getLocaleMessageViewCount = () => {
    const currentViewCount = localStorage.get(MESSAGE_VIEW_COUNT_KEY) || 0;
    return parseInt(currentViewCount, 10) || 0;
};

export const hideLocaleMessage = () => {
    setCookie({
        name: HIDE_LOCALE_MESSAGE_COOKIE,
        value: true,
        domain: FIVERR_BASE_DOMAIN_NAME,
        days: COOKIE_EXPIRATION,
    });
};

export const localeMessageIsHidden = () => getCookie(HIDE_LOCALE_MESSAGE_COOKIE, false);

export const inTestGroup = (abTests = {}, testId) => {
    const variation = Number(abTests[testId]);
    return variation > CONTROL_VARIANT;
};
