import React from 'react';
import { theme } from '@fiverr-private/theme';
import { SYSTEM_ICON_SIZES } from '../../constants';
import type { SystemIconType } from '../../types';

const SpinnerIcon = ({ size = 'sm', color = 'currentColor' }: SystemIconType) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={SYSTEM_ICON_SIZES[size]}
    height={SYSTEM_ICON_SIZES[size]}
    viewBox="0 0 32 32"
    fill={theme.colors[color]}
  >
    <path d="M16 0a16 16 0 0 0 0 32 16 16 0 0 0 0-32m0 4a12 12 0 0 1 0 24 12 12 0 0 1 0-24" opacity={0.18} />
    <path d="M16 0a16 16 0 0 1 16 16h-4A12 12 0 0 0 16 4z">
      <animateTransform
        attributeName="transform"
        dur="0.8s"
        from="0 16 16"
        repeatCount="indefinite"
        to="360 16 16"
        type="rotate"
      />
    </path>
  </svg>
);
SpinnerIcon.displayName = 'SpinnerIcon';
export default SpinnerIcon;
