import React from 'react';
import { fill } from '../../PropTypes';

const DEFAULT_FILL = '#555';

const NavIcon = ({ fill = DEFAULT_FILL, ...props }) => (
    <svg {...props} xmlns="http://www.w3.org/2000/svg" width="23" height="19" viewBox="0 0 23 19">
        <rect y="16" width="23" height="3" rx="1.5" fill={fill} />
        <rect width="23" height="3" rx="1.5" fill={fill} />
        <rect y="8" width="23" height="3" rx="1.5" fill={fill} />
    </svg>
);

NavIcon.propTypes = {
    fill,
};

export default NavIcon;
