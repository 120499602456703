import React, { Suspense, useEffect, useState, lazy } from 'react';
import { useMediaQuery } from 'react-responsive';
import { getContext } from '@fiverr-private/fiverr_context';
import { getShortLanguageCode } from '@fiverr-private/localization';
import { EventTypes } from '../../utils/events/types';
import { source, user } from '../../lib/types';
import { reportEvents } from '../../utils/events';
import { LIONS_BANNER_LOCAL_SITE_REDIRECT_ID } from '../../lib/constants';
import { MEDIA_GRID_LARGE, MESSAGE_VIEW_COUNT_THRESHOLD, PROMOTED_COUNTRIES_MAP, PROMOTED_SOURCES } from './constants';
import { getLocaleMessageViewCount, hideLocaleMessage, localeMessageIsHidden, inTestGroup } from './utils';

const ModalComponent = lazy(() =>
    import(/* webpackChunkName: 'LocaleMessageModal' */ './LocaleMessageModal').catch(() => null as any)
);

const PopoverComponent = lazy(() =>
    import(/* webpackChunkName: 'LocaleMessagePopover' */ './LocaleMessagePopover').catch(() => null as any)
);

interface MessageComponentWrapperProps {
    className?: string;
    source?: source;
    user?: user;
    rollouts?: Record<string, boolean>;
}

const withLocalMessage = ({ LanguageSelectionComponent }) => {
    const MessageComponentWrapper = (props: MessageComponentWrapperProps) => {
        const { className, source, user, rollouts = {} } = props;
        const { locale = '', browserLanguage = '', countryCode = '', userId, abTests } = getContext();

        const [RenderedComponent, setRenderedComponent] = useState(<LanguageSelectionComponent {...props} />);
        const isMobile = useMediaQuery({ maxWidth: MEDIA_GRID_LARGE });
        const biEnrichmentParams = { source, user, language: locale, browserLanguage };
        const isEligibleForPromotion =
            !!source && PROMOTED_SOURCES.includes(source) && PROMOTED_COUNTRIES_MAP[countryCode];

        // messageIsHidden - did/didn't pass all visibility conditions
        useEffect(() => {
            let Component;
            const isInLocalSiteRedirectRollout = rollouts?.inLocalSiteRedirectRollout;
            const isInLocalSiteRedirectTest =
                isInLocalSiteRedirectRollout && inTestGroup(abTests, LIONS_BANNER_LOCAL_SITE_REDIRECT_ID);
            const messageIsHidden = localeMessageIsHidden();
            const LocaleMessageViewCount = getLocaleMessageViewCount();
            const shouldDisplayMessage =
                isEligibleForPromotion &&
                !messageIsHidden &&
                getShortLanguageCode(locale) !== getShortLanguageCode(PROMOTED_COUNTRIES_MAP[countryCode]) &&
                !userId &&
                !isInLocalSiteRedirectTest;

            const onMessageClose = () => {
                reportEvents(EventTypes.language.localeMessage.closed, biEnrichmentParams);
            };

            const onLanguageSelectionOpen = () => {
                if (!messageIsHidden) {
                    hideLocaleMessage();
                }
            };

            const enhancedProps = {
                ...props,
                onLanguageSelectionOpen,
            };
            const messageProps = {
                biEnrichmentParams,
                onMessageClose,
                className,
            };

            if (shouldDisplayMessage) {
                reportEvents(EventTypes.language.localeMessage.show, biEnrichmentParams);

                if (LocaleMessageViewCount >= MESSAGE_VIEW_COUNT_THRESHOLD - 1) {
                    hideLocaleMessage();
                }

                Component = isMobile ? (
                    <Suspense fallback={null}>
                        <ModalComponent {...messageProps} />
                        <LanguageSelectionComponent {...enhancedProps} />
                    </Suspense>
                ) : (
                    <Suspense fallback={<LanguageSelectionComponent {...enhancedProps} />}>
                        <PopoverComponent {...messageProps}>
                            <LanguageSelectionComponent {...enhancedProps} />
                        </PopoverComponent>
                    </Suspense>
                );
            } else {
                Component = <LanguageSelectionComponent {...props} />;
            }

            setRenderedComponent(Component);
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);

        return RenderedComponent;
    };

    return MessageComponentWrapper;
};

export default withLocalMessage;
