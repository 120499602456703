import { BIGQUERY_GROUP } from '../../constants';

/**
 * Name of the Mixpanel event.
 * @type {String}
 */
export const MIXPANEL_EVENT_NAME = 'Clicked sign in';

/**
 * Type name of the BigQuery event.
 * @type {String}
 */
export const BIGQUERY_TYPE = 'clicked_header.sign_in.button_click_header';

/**
 * BigQuery event to send on click.
 * @type {Object}
 */
export const BIGQUERY_EVENT = {
    type: BIGQUERY_TYPE,
    group: BIGQUERY_GROUP,
};

/**
 * Mixpanel event to send on click.
 * @type {Object}
 */
export const MIXPANEL_EVENT = {
    eventName: MIXPANEL_EVENT_NAME,
    eventData: { 'Source Component': 'Header' },
};
