import React from 'react';
import { node } from 'prop-types';

import styles from './OptionItem.module.scss';

export const OptionItem = ({ icon, title, subTitle }) => {
    const optionEl = (
        <div>
            <div className="text-semi-bold co-text-darker">{title}</div>
            <div className="tbody-6 co-text-dark">{subTitle}</div>
        </div>
    );

    if (icon) {
        return (
            <div className={styles.container}>
                {icon}
                {optionEl}
            </div>
        );
    }

    return optionEl;
};

OptionItem.propTypes = {
    icon: node,
    title: node,
    subTitle: node,
};
