import {
    triggerSignIn as legacyTriggerSignIn,
    triggerSignUp as legacyTriggerSignUp,
    triggerSignInForBlockedAction as legacyTriggerSignInForBlockedAction,
    triggerSignUpForBlockedAction as legacyTriggerSignUpForBlockedAction,
} from '@fiverr-private/user_session';

import { isBrowser } from '../../../utils/isBrowser';
import { type ModalOptions } from '../../../shared/constants/modalOptions';
import { type TriggerParams } from '../types';

const TriggerType = {
    BLOCKED: 'blocked_action',
};

const mapToLegacyModalOptions = (modalOptions: ModalOptions = {}) => {
    const legacyModalOptions = { ...modalOptions };
    delete legacyModalOptions.mode;

    return legacyModalOptions;
};

const triggerLegacySignIn = async (params: TriggerParams) => {
    const { modalOptions = {} } = params;
    const legacyModalOptions = mapToLegacyModalOptions(modalOptions);

    if (modalOptions.triggerType === TriggerType.BLOCKED) {
        return legacyTriggerSignInForBlockedAction({
            ...params,
            modalOptions: legacyModalOptions,
        });
    }

    return legacyTriggerSignIn({
        ...params,
        modalOptions: legacyModalOptions,
    });
};

const triggerLegacySignUp = async (params: TriggerParams) => {
    const { modalOptions = {} } = params;
    const legacyModalOptions = mapToLegacyModalOptions(modalOptions);

    if (modalOptions.triggerType === TriggerType.BLOCKED) {
        return legacyTriggerSignUpForBlockedAction({
            ...params,
            modalOptions: legacyModalOptions,
        });
    }

    return legacyTriggerSignUp({
        ...params,
        modalOptions: legacyModalOptions,
    });
};

export const triggerLegacySigningFlow = async (params: TriggerParams) => {
    const { modalOptions = {}, biEnrichmentParams = {} } = params;

    if (!isBrowser()) {
        return;
    }

    if (modalOptions.mode === 'sign_up') {
        return triggerLegacySignUp({ modalOptions, biEnrichmentParams });
    }

    return triggerLegacySignIn({ modalOptions, biEnrichmentParams });
};
