import { triggerService } from '../services/triggerService';
import { SigningModalTypeValues } from '../../shared/constants/signingModalType';
import { inBusinessExperience } from '../../utils/isBusiness';
import { isBrowser } from '../../utils/isBrowser';
import { businessOpenSignUp, businessOpenSignIn } from './business';
import { type TriggerParams, type ExternalTriggerParams } from './types';
import { triggerLegacySigningFlow } from './legacy';

declare global {
    interface Window {
        initialData: {
            Identification: object;
            UserSession: object;
        };
    }
}

const triggerSigningFlow = async ({ modalOptions = {}, biEnrichmentParams = {} }: TriggerParams) => {
    if (!isBrowser()) {
        return;
    }

    const isInTest = 'Identification' in window.initialData;
    if (isInTest) {
        return triggerService.trigger(SigningModalTypeValues.identification, modalOptions, biEnrichmentParams);
    }

    return triggerLegacySigningFlow({ modalOptions, biEnrichmentParams });
};

export const triggerSignUp = async (params: ExternalTriggerParams = {}) => {
    const { modalOptions = {}, biEnrichmentParams = {} } = params;

    if (inBusinessExperience()) {
        await businessOpenSignUp(params);
        return;
    }

    return triggerSigningFlow({
        modalOptions: {
            ...modalOptions,
            mode: 'sign_up',
        },
        biEnrichmentParams,
    });
};

export const triggerSignIn = async (params: ExternalTriggerParams = {}) => {
    const { modalOptions = {}, biEnrichmentParams = {} } = params;

    if (inBusinessExperience()) {
        await businessOpenSignIn(params);
        return;
    }

    return triggerSigningFlow({
        modalOptions: {
            ...modalOptions,
            mode: 'sign_in',
        },
        biEnrichmentParams,
    });
};
