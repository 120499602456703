import React from 'react';
import { string } from 'prop-types';
import pathfinder from '@fiverr-private/pathfinder';
import Link from '../Link';
import { TOP_NAV_SOURCE } from '../../../constants';

export const BecomeSellerLink = ({ source, ...props }) => {
    const path = pathfinder(
        'start_selling',
        {},
        {
            query: {
                source,
            },
        }
    );

    return (
        <Link {...props} href={path} className="nav-link" rel="nofollow">
            {(i18n) => i18n.t('header.become_seller')}
        </Link>
    );
};

BecomeSellerLink.propTypes = {
    source: string,
};

BecomeSellerLink.defaultProps = {
    source: TOP_NAV_SOURCE,
};
