export const SUPPORTED_FIVERR_LOCALES_MAP = {
    'de-DE': 'Deutsch',
    'en-US': 'English',
    es: 'Español',
    'fr-FR': 'Français',
    'it-IT': 'Italiano',
    'nl-NL': 'Nederlands',
    'pt-BR': 'Português',
};

export const SUPPORTED_FIVERR_LANGUAGES = {
    EN: 'en',
    PT: 'pt',
    IT: 'it',
    ES: 'es',
    DE: 'de',
    NL: 'nl',
    FR: 'fr',
};

export const LOCALE_TO_SUBDOMAIN_MAP = {
    'en-US': 'www',
    'de-DE': 'de',
    es: 'es',
    'fr-FR': 'fr',
    'pt-BR': 'br',
    'it-IT': 'it',
    'nl-NL': 'nl',
};

export const DEFAULT_FIVERR_LOCALE = 'en-US';
