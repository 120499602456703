import React, { useContext } from 'react';
import { noop } from 'lodash';
import classnames from 'classnames';
import Context from '../../contexts/app';
import { DEFAULT_CURRENCY, STYLES } from '../../lib/constants';
import { currency } from '../../lib/types';
import { currencyLabelFormatter } from '../../utils/currency';

import './style.scss';

export interface CurrencySelectionTriggerProps {
    currency?: currency;
    style?: string;
    onTrigger?: () => void;
    className?: string;
}

const CurrencySelectionTrigger = ({
    currency = DEFAULT_CURRENCY,
    style = STYLES.DEFAULT,
    onTrigger = noop,
    className,
}: CurrencySelectionTriggerProps) => {
    const { titleClassName } = useContext(Context);
    const wrapperClass = classnames(
        'selection-trigger',
        'currency-selection-trigger',
        'text-body-2',
        className,
        titleClassName,
        style
    );

    return (
        <button className={wrapperClass} onClick={onTrigger}>
            {currencyLabelFormatter(currency)}
        </button>
    );
};

export default CurrencySelectionTrigger;
