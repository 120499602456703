import { sendGraph } from '@fiverr-private/futile';
import { stats } from '@fiverr-private/obs';
import { DEFAULT_PERCENTAGE, DEFAULT_PREFIX, DEFAULT_TTL } from './constants';

/**
 * Report count event to grafana (with increment of 1).
 * If Fiverr.sendGraph exists globally, will send with futile, else with obs.
 * @param {Object} event - grafana event.
 */
export const sendGraphCount = (event) => {
    if (typeof Fiverr === 'object' && typeof global.Fiverr.sendGraph === 'function') {
        sendGraph(event);
    } else {
        stats.count(DEFAULT_PREFIX, event.metric);
    }
};

export const baseParams = {
    percentage: DEFAULT_PERCENTAGE,
    ttl: DEFAULT_TTL,
};

export const createGrafanaEvent =
    (type) =>
    (params = {}) => ({
        ...baseParams,
        label: constructLabel(type, params),
        metric: constructMetric(type, params),
    });

const constructLabel = (type, params) => `${DEFAULT_PREFIX}.${constructMetric(type, params)}`;

const constructMetric = (type, params) => {
    const { source } = params;
    const normalizedSource = source ? `${source}.` : '';

    return `${normalizedSource}${type}`;
};
