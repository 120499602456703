import { type Types, utilityClasses } from '@fiverr-private/theme';

export const CENTERED: Types.LayoutType = {
    paddingX: {
        default: '6',
        md: '8',
    },
    paddingTop: {
        default: '6',
        md: '8',
    },
    paddingBottom: {
        default: '12',
        md: '16',
    },
    maxWidth: {
        default: '1400px',
    },
    width: { default: '100%' },
    margin: { default: 'autoX' },
    className: utilityClasses.paddingX_0_xxl,
};
