export const NUMBER_STYLES = {
    DECIMAL: 'decimal',
    PERCENT: 'percent',
};

export const NUMBER_FORMATTING_OPTIONS = {
    style: {
        validator: (value) => Object.values(NUMBER_STYLES).includes(value),
        validatorFallbackValue: NUMBER_STYLES.DECIMAL,
        defaultValue: NUMBER_STYLES.DECIMAL,
    },
    minimumFractionDigits: {},
    maximumFractionDigits: {},
};
