import React, { forwardRef } from 'react';
import classNames from 'classnames';
import { Box } from '@fiverr-private/theme';
import { CENTERED } from './constants';
import { LayoutProps } from './types';

const Layout = forwardRef<HTMLElement, LayoutProps>(
  ({ children, centered, borderStyle, borderColor, className: customClassName, ...restProps }: LayoutProps, ref) => {
    const centeredStyles = centered ? CENTERED : {};
    const { borderLeftWidth, borderTopWidth, borderBottomWidth, borderRightWidth, borderWidth } = restProps;
    const { className, ...stylingProps } = centeredStyles;

    const borderStylingReset =
      borderLeftWidth || borderTopWidth || borderBottomWidth || borderRightWidth || borderWidth
        ? {
            borderStyle: borderStyle || 'solid',
            borderColor: borderColor || 'border',
          }
        : undefined;

    return (
      <Box
        ref={ref}
        {...stylingProps}
        {...borderStylingReset}
        boxSizing="borderBox"
        className={classNames(className, customClassName)}
        {...restProps}
      >
        {children}
      </Box>
    );
  }
);

Layout.displayName = 'Layout';

export default Layout;
